<template>
  <div>
    <a-row>
      <a-col class="app-home">
        <p>Welcome!</p>
        <router-link to="/login">
          <a-button type="primary" class="login-form-button" size="large">
            Log in
          </a-button>
        </router-link>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'Home'
  }
</script>

<style>
.app-home {
  max-width: 460px;
  margin: 0 auto;
  padding: 30px 20px 0;
  text-align: center;
}
</style>