import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'  

Vue.use(Vuex);

const state = {
    'reph': {
        user: {},
        token: localStorage.getItem('token'),
        refreshToken: null
    }
}

const store = new Vuex.Store({
    state,
    getters: {
        getUser: (state) => {
            return state.reph.user;
        },
        getUserLoginId: (state) => {
            return state.reph.user.reph_login_id;
        },
        getToken: (state) => {
            return state.reph.token;
        },
        getRefreshToken: (state) => {
            return state.reph.refreshToken;
        },
        isAuthenticated: state => !!state.reph.user
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        setUserLoginId(context, loginId) {
            context.commit('setUserLoginId', loginId);
        },
        setToken(context, token) {
            context.commit('setToken', token);
        },
        setRefreshToken(context, token) {
            context.commit('setRefreshToken', token);
        }
    },
    mutations: {
        setUser(state, user) {
            state.reph.user = user;
        },
        setUserLoginId(state, loginId) {
            state.reph.user.reph_login_id = loginId;
        },
        setToken(state, token) {
            state.reph.token = token;
        },
        setRefreshToken(state, token) {
            state.reph.refreshToken = token;
        }
    },
    plugins: [createPersistedState()]
});

export default store;