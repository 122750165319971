<template>

    <div>

        <a-page-header v-if="isLoggedIn"
            style="margin:0 0 24px;"
            class="app-page-title"
            :ghost="false"
            title="State Reporting Logs"
        />

        <div v-if="$route.query.type == 'contacted' || $route.query.type == 'activated' || $route.query.type == 'critical'">

            <a-card :bordered="false" style="margin:0 0 24px;">

                <a-row>

                    <a-col class="form-container">

                        &nbsp;

                    </a-col>

                </a-row>

            </a-card>

        </div>

        <div v-else>
            
            <a-card :bordered="false" style="margin:0 0 24px;">

                <a-row>

                    <a-col class="form-container">

                        <error v-if="error" :error="error" />

                        <div class="date-range-filter">
                            <div class="year-month-container">
                                <span class="span-label">Select Year and Month:</span>
                                <a-month-picker
                                    v-model="yearMonth"
                                    class="year-month"
                                    placeholder="Year - Month"
                                    @change="onChangeYearMonth"
                                />
                            </div>
                        </div>
                        
                        <a-table
                            class="table-log-summary"
                            size="small"
                            :rowKey="record => record.id"
                            :loading="isSummaryLoading"
                            :columns="dataSummaryColumns"
                            :dataSource="summaryStats"
                            :pagination="pagination"
                            @change="handleTableChange"
                        >

                            <div slot="action" slot-scope="text, record">
                                <a-button type="primary" size="small" @click="getLogLink" :data-id="record.id">
                                    Download Log
                                </a-button>
                            </div>

                        </a-table>

                    </a-col>
                </a-row>
                
            </a-card>

        </div>

    </div>

</template>

<script>

    import axios from '../../api'
    import Error from '../Error.vue'
    import moment from 'moment'
    // import Chart from 'chart.js'

    const dataSummaryColumns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Order ID',
            dataIndex: 'client_order_full_id',
        },
        {
            title: 'DOH',
            dataIndex: 'doh',
        },
        {
            title: 'Assay',
            dataIndex: 'alinity_assay',
        },
        {
            title: 'Method',
            dataIndex: 'method',
        },
        {
            title: 'Time Submitted',
            dataIndex: 'submitted_at',
        },
        {
            title: '',
            dataIndex: "state_reporting_logs.id",
            scopedSlots: {
                customRender: "action"
            }
        }
    ];

    export default {

        name: 'State Reporting Logs',

        components: {
            Error
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            yearMonthFormatted: function() {
                return moment(this.yearMonth).format(this.dateFormat);
            }
        },

        mounted: function() {

            this.getSummaryStats();
            
            console.log(this.yearMonthFormatted);

            // this.renderChart(this.chartData);

            this.$nextTick(function () {
                // console.log('section: ' + this.section);
                // console.log('reviewStatus: ' + this.reviewStatus);
            })
        },

        data: function() {
            return {
                dataSummaryColumns,
                error: '',
                errors: {},
                chartData: {},
                pagination: {},
                summaryStats: [],
                dataSummary: [],
                yearMonth: moment().subtract(1, 'month'),
                dateTo: moment(),
                dateFormat: 'YYYY-MM',
                isSummaryLoading: false
            }
        },
 
        methods: {

            async getSummaryStats() {

                try {

                    this.isSummaryLoading = true;

                    this.dataSummary = [];

                    var uriSummary = 'orders/state-reporting-logs?year_month=' + moment(this.yearMonth).format(this.dateFormat) + (this.pagination.current === undefined ? '' : '&page=' + this.pagination.current);

                    console.log('uriSummary');
                    console.log(uriSummary);

                    let res = await axios.get(uriSummary, {
                        headers: { 'Authorization': 'Bearer ' + this.token }
                    });

                    console.log('res');
                    console.log(res);

                    this.summaryStats = res.data.state_reporting_logs;

                    this.pagination = {
                        ...this.pagination,
                        size: 'small',
                        pageSize: res.data.pagination.item_count_per_page,
                        total: res.data.pagination.total_item_count,
                        showTotal: (num, range) => range[0] + ' to ' + range[1] + ' of ' + num + ' Logs'
                    };

                    console.log('summaryStats');
                    console.log(this.summaryStats);

                    console.log('pagination');
                    console.log(this.pagination);

                    this.isSummaryLoading = false;

                } catch (error) {
                    console.log('.....error.....');
                    console.log(error);
                }

            },

            onChangeYearMonth(date, dateString) {
                this.yearMonth = dateString;
                this.getSummaryStats();
                this.pagination = {
                    ...this.pagination,
                    pageSize: 2
                };
            },

            getLogLink: async function (event) {
                var logId = event.target.getAttribute('data-id');
                console.log(logId);
                window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'state-reportings/' + logId + '/pdf?access_token=' + this.token, '_blank');
            },

            handleTableChange(pagination, filters, sorter) {
                this.pagination = {
                    ...this.pagination,
                    current: pagination.current
                };

                this.getSummaryStats({
                    results: pagination.pageSize,
                    page: pagination.current,
                    sortField: sorter.field,
                    sortOrder: sorter.order,
                    ...filters
                });
            },

        }

    }
</script>

<style>
.form-container > h3 {margin-bottom:12px !important;}
.date-range-filter {margin-bottom:25px;}
.date-range-filter .span-label {font-size:90%;margin-right:5px;}
.table-log-summary {margin-bottom:20px;}
.ant-statistic-title {margin-bottom:0;}
.row-summary-container {max-width:600px;margin-bottom:20px;}
.year-month-container, .date-to-container {display:inline-block;}
.year-month-container {margin-right:20px;}
.row-summary-container .ant-statistic {text-align:left;}
.table-link > a {font-weight:600;}
.table-link > span {color:#999;}
canvas{height:250px;background:#ededed;}
@media only screen and (max-width: 767.99px) {
    .year-month-container, .date-to-container {display:block;margin-bottom:15px;}
    .date-range-filter {margin-bottom:30px;}
    .date-range-filter .span-label {width:90px;display:inline-block;}
    .row-summary-container .ant-statistic {text-align:center;}
}
</style>    