<template>

    <div>

        <div class="app-page-title ant-page-header" style="margin: 0px 0px 24px;">
            <div class="ant-page-header-heading">
                <span class="ant-page-header-heading-title">
                    <span class="ant-page-header-heading-title-main"></span>
                    <span class="page-title-action">
                        <span>
                            <a @click="showModalListComment" data-comment_type="acct_id" :data-comment_type_id="patientData.id" class="num-comments"></a>
                        </span>
                        <span class="vert-divider">|</span>
                        <span><a @click="showModalAddComment">+ add comment</a></span>
                    </span>
                </span>
            </div>
        </div>

        <a-card :bordered="false" style="margin:0 0 24px;">

            <a-row>

                <a-col class="form-container">

                    <a-descriptions
                        title="Basic Info"
                        :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                        size="middle"
                        bordered
                    >
                        <a-descriptions-item label="First Name">
                        {{ patientData.first_name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Last Name">
                        {{ patientData.last_name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Gender">
                        {{ patientData.gender.label }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Birth Date">
                        {{ patientData.birth_date | moment }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Height">
                            <div v-if="patientData.height.unit == null">
                                N/A
                            </div>
                            <div v-else>
                                <span v-if="patientData.height.unit == 'cm'">{{ patientData.height.cm }} cm</span>
                                <span v-else>{{ patientData.height.ft }} feet {{ patientData.height.in }} inches</span>
                            </div>
                        </a-descriptions-item>
                        <a-descriptions-item label="Weight">
                            <div v-if="patientData.weight.unit == null">
                                N/A
                            </div>
                            <div v-else>
                                <span v-if="patientData.weight.unit == 'kg'">{{ patientData.weight.kg }} kg</span>
                                <span v-else>{{ patientData.weight.lb }} lbs</span>
                            </div>
                        </a-descriptions-item>
                    </a-descriptions>

                    <a-descriptions
                        title="Contact Info"
                        :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                        size="middle"
                        bordered
                    >
                        <a-descriptions-item label="Address (Line 1)">
                            {{ patientData.address_1 }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Address (Line 2)">
                            {{ patientData.address_2 }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Country">
                            {{ patientData.country.name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="State / Province">
                            {{ patientData.province.name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="City">
                            {{ patientData.city }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Zip / Postal Code">
                            {{ patientData.postal_code }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Phone #">
                            {{ patientData.phone_prefix  }} {{ patientData.phone }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Email">
                            {{ patientData.email }}
                        </a-descriptions-item>
                        
                    </a-descriptions>


                    <div class="ant-descriptions-title">Tests<span>{{ numTests }}</span></div>

                    <a-table
                        class="table-stats-summary"
                        size="small"
                        :rowKey="record => record.order.id"
                        :loading="isOrderLoaded"
                        :columns="orderColumns"
                        :dataSource="orderData"
                    >
                        <div class="table-link" slot="dateRender" slot-scope="text">
                            {{ text }}
                        </div>
                        <div slot="patientRender" slot-scope="text, record">
                            {{ text }}
                            <span v-if="record.report.first_viewed_at"><a-icon type="eye" style="color:green;font-size:16px;vertical-align:middle;margin-left:3px;" /></span>

                            <div v-if="record.report.first_viewed_at" style="margin-top:5px;color:green;font-size:90%;font-weight:600;">Viewed results {{ momentDatetimeFormat(record.report.first_viewed_at) }}</div>
                        </div>
                        <div class="table-link" slot="physicianRender" slot-scope="text">
                            {{ text }}
                        </div>
                        <div class="table-link" slot="testRender" slot-scope="text, record">
                            <div style="margin-bottom:3px;">
                                <a @click="getTestLink" :data-report_id="record.report.id">{{ record.order.full_id }}</a>
                            </div>
                            <div style="margin-bottom:5px;">
                                {{ record.product.name }}
                            </div>
                            <a @click="getRequisitionLink" :data-id="record.order.id"><a-icon type="file-text" /> Requisition</a>
                        </div>
                        <div class="table-link" slot="numComments" slot-scope="text, record">
                            <div v-if="record.order.num_all_comments == 0">
                                {{ record.order.num_all_comments }}
                            </div>
                            <div v-else>
                                <div v-if="record.order.num_flagged_comments == 0">
                                    <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="record.order.id" :data-comment_type_label="record.product.name" :data-comment_type_full_id="record.order.full_id">{{ record.order.num_all_comments }} comment<span v-if="record.order.num_all_comments > 1">s</span></a>
                                </div>
                                <div v-else>
                                    <div style="margin-bottom:1px;">
                                        <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="record.order.id" :data-comment_type_label="record.product.name" :data-comment_type_full_id="record.order.full_id" class="alert">{{ record.order.num_all_comments }} comment<span v-if="record.order.num_all_comments > 1">s</span></a>
                                    </div>
                                    <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="record.order.id" :data-comment_type_label="record.product.name" :data-comment_type_full_id="record.order.full_id" class="alert">({{ record.order.num_flagged_comments }} flagged)</a>
                                </div>
                            </div>
                        </div>
                        <div class="table-link" slot="questionnaireRender" slot-scope="text, record">
                                <a @click="showModalHq" :data-id="record.order.id" :data-name="record.individual.full_name"><a-icon type="ordered-list" /> View</a>
                            </div>
                        <div class="table-link" slot="statusRender" slot-scope="text, record">
                            <div v-if="record.report.is_reviewable">
                                <div style="margin-bottom:5px;">
                                    {{ record.report.review_status }}
                                </div>
                                <div v-if="record.report.is_completed">
                                    <div style="margin-bottom:10px;">
                                        <a-button type="danger" size="small" @click="showModalSetStatus" :data-id="record.order.id" :data-report_id="record.report.id">
                                        Add Record
                                    </a-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!record.report.is_reviewable">
                                <div v-if="record.report.is_completed" style="margin-bottom:10px;">
                                    <span v-if="record.report.review_status_code == 'na'">Contact Not Required</span>
                                    <span v-if="record.report.review_status_code == 'approved'">Contact Completed</span>
                                </div>
                                <div v-if="!record.report.is_completed" style="margin-bottom:10px;">
                                    Pending
                                </div>
                            </div>
                            <a v-if="record.report.num_review_status_logs > 0" :data-id="record.order.id" :data-report_id="record.report.id" @click="showModalContactHistory"><a-icon type="history" /> History</a>
                        </div>
                        <div class="table-link" slot="reportRender" slot-scope="text, record">
                            <div v-if="record.report.is_released">
                                Released {{ record.report.released_on }}
                            </div>
                            <div v-if="!record.report.is_released && !record.report.is_completed">
                                N/A
                            </div>
                            <div v-if="!record.report.is_released && record.report.is_completed">
                                Pending
                            </div>
                        </div>
                        
                    </a-table>

                </a-col>
            </a-row>
            
        </a-card>

        <a-modal
            :title="addCommentTitle"
            class="modal-add-comment"
            v-model="addCommentVisible"
            :visible="addCommentVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="addCommentHandleOk"
            @cancel="addCommentHandleOk"
        >
            <div style="font-weight:600;margin:0 0 5px;font-size:90%;">
                At {{ currentDateTime }}:
            </div>
            <div style="margin:0 0 5px;">
                
                <a-form
                    id="form-comment"
                    :form="formComment"
                    class="comment-form"
                    layout="vertical"
                    @submit.prevent="handleCommentSubmit"
                >
                    
                    <a-form-item
                        style="margin:0;"
                        :help="errors.comment"
                    >
                        <a-textarea
                            v-decorator="[
                            'comment',
                                {
                                    rules: [{ required: true, message: 'Please enter your comment.' }],
                                    initialValue: this.targetComment.comment
                                },
                            ]"
                            placeholder="Add your comment here."
                            :auto-size="{ minRows: 5, maxRows: 10 }"
                        />
                    </a-form-item>

                    <a-form-item
                        style="margin:0 0 15px;font-weight:600;"
                    >
                        <span style="flex:0;margin-right:5px;">
                            <a-checkbox
                                v-model="is_flagged"
                            />
                        </span>
                        <span style="flex:1;">
                            Flag this comment
                        </span>
                    
                    </a-form-item>

                    <a-button :disabled="formCommentSubmitting" :loading="formCommentSubmitting" type="primary" html-type="submit" class="button-submit">
                        {{ formCommentSubmitting ? 'Submitting...' : (this.targetComment.id ? 'Update' : 'Add') + ' Comment' }}
                    </a-button>

                </a-form>

            </div>
        </a-modal>

        <a-modal
            :title="listCommentTitle"
            class="modal-list-comment"
            v-model="listCommentVisible"
            :visible="listCommentVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="listCommentHandleOk"
            @cancel="listCommentHandleOk"
        >
            <div style="overflow-y:scroll;max-height:450px;">
                <a-list item-layout="horizontal" size="large" :data-source="commentData">
                    <a-list-item slot="renderItem" key="item.id" slot-scope="item">
                        <a class="a-edit" v-if="item.is_editable == 1" slot="actions" @click="showModalEditComment" :data-comment_id="item.id">edit</a>
                        <div>
                            <span class="comment-meta">
                                {{ item.created_at | momentDatetime }} by {{ item.from_name }}
                                <a-icon type="flag" v-if="item.is_flagged" theme="filled" :style="{ color: '#cc0000', fontSize: '16px', margin: '0 0 0 7px' }" />
                            </span> 
                            {{ item.comment }}
                        </div>
                    </a-list-item>
                </a-list>
            </div>
        </a-modal>

        <a-modal
            title="Contact History"
            v-model="historyVisible"
            :visible="historyVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="historyHandleOk"
            @cancel="historyHandleOk"
            >

            <a-timeline style="overflow-y:scroll; max-height: 400px; margin:-10px;padding:10px;" v-if="historyLog.length > 0">
                <a-timeline-item  v-for="(item, index) in historyLog" :key="index">
                    <div style="font-weight:bold;margin-bottom:2px;">
                        {{ momentDatetimeFormat(item.recorded_at) }}:
                        <span v-if="item.is_contact_successful == '1'">Sucessful Contact</span>
                        by {{item.recorded_by}}
                    </div>
                    {{ item.comment }}
                    <div style="padding-top:5px;" v-if="item.from_status != item.to_status">Status Change: {{ item.to_status }}</div>
                </a-timeline-item>
            </a-timeline>

            <div v-if="historyLog.length == 0">
                No history found.
            </div>
            
        </a-modal>

        <a-modal
            title="Health Questionnaire"
            class="modal-hq"
            v-model="hqVisible"
            :visible="hqVisible"
            :confirm-loading="confirmLoading"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="hqHandleOk"
            @cancel="hqHandleOk"
            >
            <div v-for="(item, index) in hqQuestion" :key="index">
                <div>
                    <span style="font-weight:bold;">{{ item.question.question }}: </span>
                    <span>{{ item.answer.label }}</span>
                </div>
                <a-divider type="horizontal" style="margin:12px 0;" />
            </div>
        </a-modal>

        <a-modal
            title="Add Record"
            v-model="statusVisible"
            :visible="statusVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            >

            <p style="margin:0 0 30px;">You are about to add a contact record for <span style="font-weight:bold" v-html="statusPatientName"></span>.</p>

            <a-form-model
                layout="horizontal"
                ref="formStatus"
                :model="formStatus"
                :rules="formStatusRules"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 24 }"
                @submit.prevent="handleStatusSubmit"    
                >

                <a-input v-model="formStatus.orderId" type="hidden" />
                <a-input v-model="formStatus.reportId" type="hidden" />

                <a-form-model-item>
                    <span class="form-label">Was the contact successful?</span>
                    <a-radio-group v-model="formStatus.is_contact_successful">
                        <a-radio value="1">
                        Yes
                        </a-radio>
                        <a-radio value="0">
                        No
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item>
                    <span class="form-label">Release results?</span>
                    <!-- <a-radio-group
                        v-model="formStatus.to_status"
                        :disabled="formStatus.is_contact_successful == 0"
                    > -->
                    <a-radio-group
                        v-model="formStatus.to_status"
                    >
                        <a-radio value="approved">
                        Yes
                        </a-radio>
                        <a-radio value="required">
                        No
                        </a-radio>  
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="formStatus.is_contact_successful == 0 && formStatus.to_status == 'approved'">
                    <span class="form-label">Display clinic message?</span>
                    <a-checkbox
                        v-model="formStatus.show_letter"
                    />
                    <span style="margin-left:8px;">Yes, display clinic message.</span>
                </a-form-model-item>
                <a-form-model-item class="txt" prop="comment">
                    <p class="form-label">Comment:</p>
                    <a-input
                        v-model="formStatus.comment"
                        type="textarea"
                    />
                </a-form-model-item>
                <a-form-model-item>
                    <a-button type="primary" html-type="submit" class="status-form-button">
                        Submit
                    </a-button>
                    <a-button type="cancel" style="margin-left:8px;" @click="statusHandleOk">
                        Cancel
                    </a-button>
                </a-form-model-item>
            </a-form-model>

        </a-modal>

    </div>

</template>

<script>

    import axios from '../../api'
    import moment from 'moment'

    const orderColumns = [
        {
            title: 'Date',
            dataIndex: 'order.activated_on',
            scopedSlots: { customRender: 'dateRender' }
        },
        {
            title: 'Referring Physician',
            dataIndex: 'reph.name',
            scopedSlots: { customRender: 'physicianRender' }
        },
        {
            title: 'Test',
            scopedSlots: { customRender: 'testRender' }
        },
        {
            title: '# Comments',
            scopedSlots: { customRender: 'numComments' }
        },
        {
            title: 'Health Questionnaire',
            dataIndex: "report.is_completed",
            scopedSlots: { customRender: 'questionnaireRender' }
        },
        {
            title: 'Test Status',
            scopedSlots: { customRender: 'statusRender' }
        },
        {
            title: 'Report Release',
            scopedSlots: { customRender: 'reportRender' }
        },
    ];

    export default {

        name: 'Patient',

        components: {
            
        },

        filters: {
            moment: function (date) {
                return moment(date).format('MMM D, YYYY');
            },
            momentDatetime: function (date) {
                return moment(date).format('MMM D, YYYY HH:mm:ss');
            }
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken}
        },

        mounted: function() {

            this.getPatient();

            this.$nextTick(function () {
                // console.log('section: ' + this.section);
                // console.log('reviewStatus: ' + this.reviewStatus);
            })
        },

        beforeCreate() {
            this.formComment = this.$form.createForm(this, { name: 'normal_comment' });
        },

        data: function() {

            let validateComment = (rule, value, callback) => {
                // console.log('validateComment loading.');
                // console.log(rule);
                // console.log(value);
                // console.log(this.formStatus.to_status);
                // if (value === '' && this.formStatus.to_status == 'approved') {
                    // console.log('validateComment failed.');
                    // callback(new Error('Comment is required if "Okay to release result" is selected.'));
                // }
                // else {
                    // console.log('validateComment passed.');
                    callback();
                // }
            };
            
            return {
                error: '',
                errors: {},
                numTests: '',
                orderColumns,
                historyLog: [],
                hqQuestion: [],
                orderData: [],
                patientData: {
                    gender: {},
                    height: {},
                    weight: {},
                    country: {},
                    province: {}
                },
                targetComment: {
                    id: '',
                    comment: '',
                    is_flagged: false
                },
                numComments: {},
                commentData: [],
                currentDateTime: moment().format('lll'),
                validateStatus: '',
                addCommentTitle: '',
                listCommentTitle: '',
                statusPatientName: '',
                formStatus: {
                    orderId: '',
                    reportId: '',
                    recorded_at: moment(new Date(), 'YYYY-MM-DD hh:mm:ss'),
                    is_contact_successful: '0',
                    to_status: 'required',  
                    show_letter: false,
                    comment: ''
                },
                formStatusRules: {
                    comment: [{
                        validator: validateComment,
                        // required: true,
                        // message: 'Please enter comment.',
                        trigger: 'change'
                    }]  
                },
                is_flagged: false,
                confirmLoading: false,
                isPatientLoaded: false,
                hqVisible: false,
                historyVisible: false,
                addCommentVisible: false,
                listCommentVisible: false,
                statusVisible: false,
                formCommentSubmitting: false,
                isOrderLoaded: false,

            }
        },
 
        methods: {

            async getPatient() {

                this.isPatientLoaded = true;

                var uriPatient = 'users/' + this.$route.params.acct_id;

                let res1 = await axios.get(uriPatient, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.patientData = res1.data.user;

                var uriOrders = 'orders?acct_id=' + this.$route.params.acct_id;

                let res2 = await axios.get(uriOrders, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.orderData = res2.data.client_orders;

                this.numTests = ' (' + this.orderData.length + ')';

                this.getPageTitle();
                this.getNumComments();

                this.isPatientLoaded = false;
                this.isOrderLoaded = false;

            },

            async getPageTitle() {

                var title = 'Patient - ' + this.patientData.full_name + ' (ID: ' + this.patientData.id + ')';

                document.getElementsByClassName('ant-page-header-heading-title-main')[0].innerHTML = title;

            },

            async getNumComments() {

                var uriCommentCount = 'comments/count?acct_id=' + this.$route.params.acct_id;

                let res = await axios.get(uriCommentCount , {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.numComments = res.data.count;

                if (parseInt(this.numComments.flagged) > 0) {
                    document.getElementsByClassName('num-comments')[0].classList.add('alert');
                }

                document.getElementsByClassName('num-comments')[0].innerHTML = this.numComments.all + ' comment' + (parseInt(this.numComments.all) > 1 ? 's' : '') + (parseInt(this.numComments.flagged) > 0 ? ' (' + parseInt(this.numComments.flagged) + ' flagged)' : '');

            },

            getRequisitionLink: async function(event) {
                var orderId = event.target.getAttribute('data-id');
                window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/requisition?access_token=' + this.token, '_blank');
            },

            getTestLink: async function(event) {
                var reportId = event.target.getAttribute('data-report_id');
                window.location.href = process.env.BASE_URL + 'account/test/' + reportId;
            },

            showModalAddComment: async function() {
                this.targetComment.comment = '';
                this.targetComment.id = '';
                this.targetComment.is_flagged = false;
                this.formComment.resetFields('comment'); 
                this.getNumComments();

                var modalTitle = 'Patient ID ' + this.$route.params.acct_id;
                this.addCommentTitle = 'Add Comment: ' + modalTitle;

                this.listCommentVisible = false;
                this.addCommentVisible = true;
            },

            showModalEditComment: async function(event) {
                var commentId = event.target.getAttribute('data-comment_id');
                console.log('targeted comment_id: ' + commentId);

                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'comments/' + commentId, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.addCommentTitle = 'Edit Comment ID: ' + commentId;

                this.targetComment = res.data.comment;
                this.is_flagged = this.targetComment.is_flagged;

                this.listCommentVisible = false;
                this.addCommentVisible = true;
            },

            showModalListComment: async function(event) {
                this.addCommentVisible = false;
                this.listCommentVisible = true;

                var modalTitle = event.target.getAttribute('data-comment_type') == 'acct_id' ? 'Patient ID ' + this.patientData.id : event.target.getAttribute('data-comment_type_label') + ' (ID: ' + event.target.getAttribute('data-comment_type_full_id') + ')';

                this.listCommentTitle = 'List Comment: ' + modalTitle;

                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'comments?' + event.target.getAttribute('data-comment_type') + '=' + event.target.getAttribute('data-comment_type_id'), {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.commentData = res.data.comments;

            },

            showModalHq: async function(event) {
                this.hqVisible = true;
                var orderId = event.target.getAttribute('data-id');

                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/health-questionnaire', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                // console.log('......showModalHq........');
                // console.log(res.data.health_questionnaire);
                // console.log(res.data.health_questionnaire.question);
                // console.log(res.data.health_questionnaire.answer);

                this.hqQuestion = res.data.health_questionnaire;

            },

            showModalContactHistory: async function(event) {
            
                this.historyVisible = true;

                var reportId = event.target.getAttribute('data-report_id');

                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId + '/review-status-logs', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                // console.log('......showModalHistory........');
                // console.log(reportId);
                // console.log(res.data.logs);

                this.historyLog = res.data.logs;

            },

            showModalSetStatus: async function(event) {
                this.statusVisible = true;

                var orderId = event.target.getAttribute('data-id'),
                    reportId = event.target.getAttribute('data-report_id');

                let resReport = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });
                
                this.formStatus.orderId = orderId;
                this.formStatus.reportId = reportId;
                this.formStatus.recorded_at = moment().format('YYYY-MM-DD HH:mm:ss');
                this.formStatus.comment = '';
                this.formStatus.is_contact_successful = '0';
                this.formStatus.show_letter = resReport.data.report.report.show_letter;

                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/patient', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                // console.log('......showModalSetStatus........');
                // console.log(moment().format('YYYY-MM-DD HH:mm:ss'));

                this.statusPatientName = res.data.patient.first_name + ' ' + res.data.patient.last_name;

            },

            async handleStatusSubmit() {
                
                // console.log('blah');
                // console.log(this.formStatus);
                // console.log(this.formStatus.status);
                // console.log(this.formStatus.comment);

                // console.log('validate check...');

                this.$refs.formStatus.validate(valid => {
                    if (valid) {
                        axios.post(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + this.formStatus.reportId  + '/review-status', this.formStatus, {
                            headers: { 'Authorization': 'Bearer ' + this.token }
                        }).then(() => {       
                            // res inside bracket.   
                            // console.log('....submitted.');
                            // console.log(res);
                            this.statusVisible = false;
                            location.reload();
                        })
                        .catch(() => {
                            // error inside bracket.
                            // console.log('....error.');
                            // console.log(error.response);
                        })

                        // console.log('submit!');
                    }
                    else {
                        // console.log('error submit!!');
                        return false;
                    }
                });

            },

            async handleCommentSubmit() {

                // console.log('submitting comment form.');

                try {

                    this.formCommentSubmitting = true;

                    const firstCommentRes = await this.formComment.validateFields();

                    if (firstCommentRes) {

                        console.log(firstCommentRes.comment);
                        console.log(this.is_flagged);

                        var uriPostComment = '';

                        if (this.targetComment.id) {
                            uriPostComment = 'comments/' + this.targetComment.id
                        }
                        else {
                            uriPostComment = 'comments?acct_id=' + this.$route.params.acct_id;
                        }
                        
                        const commentRes = await axios.post(uriPostComment, {
                            comment: firstCommentRes.comment,
                            is_flagged: this.is_flagged
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (commentRes) {
                            console.log('success!');
                            console.log(commentRes);
                            this.error = '';
                            this.errors = {};
                            this.validateStatus = '';
                            
                            this.formCommentSubmitting = false;
                            this.addCommentVisible = false;

                            this.$message.success('Your comment has been ' + (this.targetComment.id ? 'updated' : 'added') +' successfully.', 5);

                            this.getNumComments();
                            
                        }

                    }

                } catch(e) {
                    this.formCommentSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                    this.validateStatus = 'error';
                }
                
            },

            addCommentHandleOk() {
                this.addCommentVisible = false;
            },

            listCommentHandleOk() {
                this.listCommentVisible = false;
            },

            statusHandleOk() {
                this.statusVisible = false;
            },

            hqHandleOk() {
                this.hqVisible = false;
            },
            
            historyHandleOk() {
                this.historyVisible = false;
            },

            momentDatetimeFormat(args) {
                return moment(args).format('MMM DD, YYYY HH:mm:ss');
            },

            momentDateFormat(args) {
                return moment(args).format('MMM DD, YYYY');
            },

        }

    }
</script>

<style>
.ant-descriptions-title {font-size:14px;margin-bottom:10px;}
.ant-descriptions-view {margin-bottom:35px;}
.ant-descriptions-row .ant-descriptions-item-label {color:#333 !important;width:15%;}
.ant-descriptions-row .ant-descriptions-item-content {color:#000 !important;width:15%;}
.page-title-action {margin-left:15px;font-size:60%;font-weight:400;}
.page-title-action > span {margin:0 5px;}
.page-title-action .vert-divider {color:#ccc;}
.page-title-action .alert, .ant-table-body .alert {color:#cc0000;font-weight:600; }
</style>

<style scoped>
.comment-meta {display:block;font-size:12px;font-weight:600;color:#000;margin:0 0 3px;}
.ant-list-items > li:first-child {padding-top:0;}
.a-edit {font-size:90%;}
</style>