<template>
    <a-layout-header class="app-header">
      <div v-if="!isLoggedIn" class="app-header-wrapper justify">
        <div class="header-mid">
          <img src="../assets/logo.png">
          <span>Physician Console v{{ version }}<span class="is-staging"></span></span>
        </div>
      </div>
      <div v-if="isLoggedIn" class="app-header-wrapper">
        <div class="header-left">
          <img src="../assets/logo.png">
          <span>Physician Console v{{ version }}<span class="is-staging"></span></span>
        </div>
        <div class="header-right">
          <!--
          <a-menu
            mode="horizontal"
            class="header-right loggedin-bar"
          >
            <a-menu-item key="1" disabled>
              <a-icon type="user" />
              {{user.first_name}} {{user.last_name}}
            </a-menu-item>
            <a-divider type="vertical" />
            <a-menu-item key="2" @click="handleClick">
              Logout
            </a-menu-item>
          </a-menu>
          -->
        </div>
      </div>
    </a-layout-header>
</template>

<script>
  // import {mapGetters} from 'vuex'

  export default {
    name: 'Nav',
    mounted: function() {
      this.$nextTick(function () {
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV == 'development') {
          document.getElementsByClassName('ant-layout-header')[0].style.backgroundColor = '#fff90061';
          document.getElementsByClassName('is-staging')[0].innerHTML = ' [Staging]';
        }
        else {
          document.getElementsByClassName('ant-layout-header')[0].style.backgroundColor = '#ffffff';
        }
      })
    },
    methods: {
      handleClick() {
        this.$store.dispatch('setUser', {});
        this.$store.dispatch('setToken', null);
        this.$router.push('/home');
      }
    },
    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
    },
    data() {
      return {
        version: process.env.VUE_APP_VERSION
      }
    }
  }
</script> 

<style>
.is-staging {
  color:#cc0000;
  font-weight:600;
}
.app-header-wrapper {
  display:flex;
  height:100%;
  flex-direction:row;
  align-items:center;
}
.app-header-wrapper.justify {
  justify-content:center;
}
.header-left {
  max-width:220px;
}
.header-left > img {
  width:100%;
  display:block;  
  margin-bottom:6px;
}
.header-left > span {
  display:block;
}
.header-right {
  flex:1 0 auto;
}
.header-mid {
  margin-bottom:5px;
  max-width:220px;
  text-align: center;
  margin-top: 10px;
}
.header-mid > img {
  width:100%;
  display:block;
  margin-bottom:6px;
}
.header-mid > span {
  display:block;
}
.app-header {
  font-size:12px !important;
  padding:0 25px !important;
  top:1px;
  border-bottom:1px solid #ededed;
  z-index:9;
  color:#555;
  height:64px !important;
  line-height:100% !important;
}
.loggedin-bar {
  float:right;
  height:auto;
  padding-right:20px;
  line-height:62px !important;
  font-size:12px !important;
}
</style>