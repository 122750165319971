export default [
  {
    title: "Date Activated",
    dataIndex: "order.activated_on",
    // sorter: true,
    width: "10%",
    scopedSlots: {
      customRender: "dateActivated"
    } 
  },
  {
    title: "Referring Physician",
    dataIndex: "reph.name",
    width: "15%",
    scopedSlots: {
      customRender: "physicianAssigned"
    }
  },
  {
    title: "Test",
    dataIndex: "product.name",
    width: "15%",
    scopedSlots: {
      customRender: "product"
    }
  },
  {
    title: "Assays",
    dataIndex: "assays",
    width: "15%",
    scopedSlots: {
      customRender: "assays"
    }
  },
  {
    title: "# Comments",
    width: "10%",
    scopedSlots: {
      customRender: "numComments"
    }
  },
  {
    title: "Patient",
    dataIndex: "individual.full_name",
    width: "15%",
    scopedSlots: {
      customRender: "name"
    }
  },
  {
    title: "Test Status",
    dataIndex: "report.status",
    width: "10%",
    scopedSlots: {
      customRender: "testStatus"
    }
  },
  {
    title: "Action Required",
    dataIndex: "report.is_reviewable",
    width: "10%",
    scopedSlots: {
      customRender: "physicianActionRequired"
    }
  },
  {
    title: "State Reporting",
    dataIndex: "state_reportings",
    hidden: true,
    width: "10%",
    scopedSlots: {
      customRender: "reportingToClient"
    }
  }
];
