<template>
    <div>
        <a-row>
            <a-col class="app-login">

                <error v-if="error" :error="error" />

                <a-alert
                  v-if="$route.query.redirect"
                  description="Your session has expired, please login again."
                  type="info"
                  show-icon
                  style="margin-bottom:20px;"
                />

                <a-form
                    id="form-normal-login"
                    :form="form"
                    class="login-form"
                    layout="vertical"
                    @submit.prevent="handleSubmit"
                >

                    <a-form-item
                      label="Email"
                    >
                    <a-input                        
                        v-decorator="[
                        'email',
                        { rules: [{ required: true, message: 'Please input your email!' }] },
                        ]"
                        placeholder="johndoe@mail.com"
                        size="large"
                    >
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                    </a-form-item>
                    <a-form-item
                      label="Password"
                    >
                    <a-input-password
                        v-decorator="[
                        'password',
                        { rules: [{ required: true, message: 'Please input your Password!' }] },
                        ]"
                        type="password"
                        placeholder="Password"
                        size="large"
                    >
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                    </a-form-item>
                    <a-form-item>
                        <!--
                    <a-checkbox
                        v-decorator="[
                        'remember',
                        {
                            valuePropName: 'checked',
                            initialValue: true,
                        },
                        ]"
                    >
                        Remember me
                    </a-checkbox>
                    
                    <a class="login-form-forgot" href="">
                        Forgot password
                    </a> -->
                    <a-button type="primary" html-type="submit" class="login-form-button" size="large">
                        Log in
                    </a-button>
                    </a-form-item>
                </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axios from '../api'
    import Error from './Error.vue'

    export default {
        name: 'Login',
        components: {
            Error
        },
        data() {
            return {
                email: '',
                password: '',
                error: ''
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_login' });
        },
        methods: {

            async handleSubmit() {
                try {
                    const firstRes = await this.form.validateFields();

                    if (firstRes) {
                      
                      const response = await axios.post('auth/login', {
                          email: firstRes.email,
                          password: firstRes.password
                      });

                      this.error = '';
                      this.$store.dispatch('setUserLoginId', response.data.reph_login_id);
                      // this.$store.dispatch('setToken', response.data.access_token);
                      // this.$store.dispatch('setRefreshToken', response.data.refresh_token);

                      if (this.$route.query.redirect && this.$route.query.redirect != '') {
                        this.$router.push(this.$route.query.redirect);
                      }
                      else {
                        this.$router.push('/login-2fa');
                      }

                    }
                    
                } catch(e) {
                    this.error = 'Invalid Login!';
                    console.log(e);
                }
                
            }
        }        
    }
</script>

<style>
.ant-form-item, .ant-form label {
  font-size:12px;
}
.ant-input-lg {
  font-size:12px;
}
.app-login {
  max-width: 460px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-explain {
  font-size:12px;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-login .login-form {
  max-width: 300px;
}
#form-normal-login .login-form-forgot {
  float: right;
}
#form-normal-login .login-form-button {
  margin:10px 0 5px;
  width: 100%;
}
</style>