<template>

    <div>

        <a-page-header v-if="isLoggedIn"
            style="margin:0 0 24px;"
            class="app-page-title"
            :ghost="false"
            title="Statistics"
        />

        <div v-if="$route.query.type == 'contacted' || $route.query.type == 'activated' || $route.query.type == 'critical'">

            <a-card :bordered="false" style="margin:0 0 24px;">

                <a-row>

                    <a-col class="form-container">

                        &nbsp;

                    </a-col>

                </a-row>

            </a-card>

        </div>

        <div v-else>
            
            <a-card :bordered="false" style="margin:0 0 24px;">

                <a-row>

                    <a-col class="form-container">

                        <h3>Date Range</h3>

                        <error v-if="error" :error="error" />

                        <div class="date-range-filter">
                            <div class="date-from-container">
                                <span class="span-label">From Date:</span>
                                <a-date-picker
                                    v-model="dateFrom"
                                    class="date-from"
                                    placeholder="From Date"
                                    :format="dateFormat"
                                    @openChange="handleStartOpenChange"
                                    @change="onChangeDateFrom"
                                />
                            </div>
                            <div class="date-to-container">
                                <span class="span-label">To Date:</span>
                                <a-date-picker
                                    v-model="dateTo"
                                    class="date-to"
                                    placeholder="To Date"
                                    :format="dateFormat"
                                    :open="endOpen"
                                    @openChange="handleEndOpenChange"
                                    @change="onChangeDateTo"
                                />
                            </div>
                        </div>

                        <a-row :gutter="16" class="row-summary-container">
                            <a-col :span="8">
                                <div class="ant-statistic">
                                    <div class="ant-statistic-title"># Contacts</div>
                                    <div class="ant-statistic-content">
                                        <span class="ant-statistic-content-value">
                                            <span class="ant-statistic-content-value-int">
                                                <a href="#" @click.prevent="handleStatsLink('range', 'contacted', dateFromFormatted, dateToFormatted)">{{ summaryStats.num_contacts }}</a>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </a-col>    
                            <a-col :span="8">
                                <div class="ant-statistic">
                                    <div class="ant-statistic-title"># Critical</div>
                                    <div class="ant-statistic-content">
                                        <span class="ant-statistic-content-value">
                                            <span class="ant-statistic-content-value-int">
                                                <a href="#" @click.prevent="handleStatsLink('range', 'critical', dateFromFormatted, dateToFormatted)">{{ summaryStats.num_criticals }}</a>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="8">
                                <div class="ant-statistic">
                                    <div class="ant-statistic-title"># Orders</div>
                                    <div class="ant-statistic-content">
                                        <span class="ant-statistic-content-value">
                                            <span class="ant-statistic-content-value-int">
                                                <a href="#" @click.prevent="handleStatsLink('range', 'activated', dateFromFormatted, dateToFormatted)">{{ summaryStats.num_orders }}</a>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>

                        <a-row :gutter="[30,24]">
                            <a-col :lg="24" :xl="12">
                                <div class="ant-statistic-title" style="margin-bottom:8px;" bis_skin_checked="1"># Orders by Test ({{ dateFrom | formatDate }} - {{ dateTo | formatDate }})</div>
                                 <a-table
                                    class="table-stats-summary"
                                    size="small"
                                    :loading="isYearlyLoading"
                                    :columns="dataSummaryByProductColumns"
                                    :dataSource="summaryStats.by_products"
                                >
                                </a-table>
                            </a-col>
                            <!--
                            <a-col :lg="24" :xl="12">
                                <div class="ant-statistic-title" style="margin-bottom:8px;" bis_skin_checked="1"># Orders by Assay ({{ dateFrom | formatDate }} - {{ dateTo | formatDate }})</div>
                                <a-table
                                    class="table-stats-summary"
                                    size="small"
                                    :loading="isYearlyLoading"
                                    :columns="dataSummaryByAssayColumns"
                                    :dataSource="summaryStats.by_assays"
                                >
                                </a-table>
                            </a-col>
                            -->
                        </a-row>

                    </a-col>
                </a-row>
                
            </a-card>

            <a-card :bordered="false">
                
                <a-row>
                <a-col class="form-container">

                        <h3>Monthly</h3>

                        <div class="date-range-filter">
                            <span class="span-label">For the year:</span>
                            <a-select :default-value="yearAt" style="width: 150px" @change="handleYearChange">
                                <a-select-option :value="yearCurrent">
                                    {{ this.yearCurrent }}
                                </a-select-option>
                                <a-select-option :value="yearPrevious">
                                    {{ this.yearPrevious }}
                                </a-select-option>
                            </a-select>
                        </div>

                        <a-table
                            class="table-stats-summary"
                            size="small"
                            :loading="isYearlyLoading"
                            :columns="dataYearlyColumns"
                            :dataSource="dataYearly"
                            :pagination="{pageSize: 12}"
                        >
                            <div class="table-link" slot="numContactsRender" slot-scope="text, record">
                                <a v-if="text > 0" @click.prevent="handleMonthClick('contacted', record.key)">{{ text }}</a>
                                <span v-if="text == 0">{{ text }}</span>
                            </div>
                            <div class="table-link" slot="numCriticalsRender" slot-scope="text, record">
                                <a v-if="text > 0" @click.prevent="handleMonthClick('critical', record.key)">{{ text }}</a>
                                <span v-if="text == 0">{{ text }}</span>
                            </div>
                            <div class="table-link" slot="numOrdersRender" slot-scope="text, record">
                                <a v-if="text > 0" @click.prevent="handleMonthClick('activated', record.key)">{{ text }}</a>
                                <span v-if="text == 0">{{ text }}</span>
                            </div>
                        </a-table>

                        <!-- 

                        <a-divider />

                        <canvas id="stats-chart" v-if="isChartLoaded"></canvas> 
                        
                        -->

                    </a-col>
                </a-row>

            </a-card>

        </div>

    </div>

</template>

<script>

    import Vue from 'vue'
    import axios from '../../api'
    import Error from '../Error.vue'
    import moment from 'moment'
    // import Chart from 'chart.js'

    Vue.filter('formatDate', function(value) {
        if (value) {
            return moment(String(value)).format('MMM DD, YYYY')
        }
    });

    const dataSummaryColumns = [
        {
            title: '# Contacts',
            dataIndex: 'num_contacts'
        },
        {
            title: '# Criticals',
            dataIndex: 'num_criticals',
        },
        {
            title: '# Orders',
            dataIndex: 'num_orders',
        },
    ];

    const dataSummaryByProductColumns = [
        {
            title: 'Test Name',
            dataIndex: 'product'
        },
        {
            title: 'Total',
            dataIndex: 'num',
        },
    ];

    const dataSummaryByAssayColumns = [
        {
            title: 'Assay Name',
            dataIndex: 'assay'
        },
        {
            title: 'Total',
            dataIndex: 'num',
        },
    ];

    const dataYearlyColumns = [
        {
            title: 'Month',
            dataIndex: 'label',
        },
        {
            title: '# Contacts',
            dataIndex: 'num_contacts',
            scopedSlots: { customRender: 'numContactsRender' }
        },
        {
            title: '# Criticals',
            dataIndex: 'num_criticals',
            scopedSlots: { customRender: 'numCriticalsRender' }
        },
        {
            title: '# Orders',
            dataIndex: 'num_orders',
            scopedSlots: { customRender: 'numOrdersRender' }
        },
    ];

    export default {

        name: 'Statistics',

        components: {
            Error
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            dateFromFormatted: function() {
                return moment(this.dateFrom).format(this.dateFormat);
            },
            dateToFormatted: function() {
                return moment(this.dateTo).format(this.dateFormat);
            }
        },

        mounted: function() {

            this.getSummaryStats();
            this.getYearlyStats();
            
            console.log(this.dateFromFormatted);
            console.log(this.dateToFormatted);

            // this.renderChart(this.chartData);

            this.$nextTick(function () {
                // console.log('section: ' + this.section);
                // console.log('reviewStatus: ' + this.reviewStatus);
            })
        },

        data: function() {
            return {
                dataSummaryColumns,
                dataYearlyColumns,
                dataSummaryByProductColumns,
                dataSummaryByAssayColumns,
                error: '',
                errors: {},
                chartData: {},
                summaryStats: {},
                yearlyStats: {},
                dataSummary: [],
                dataYearly: [],
                dataByProduct: [],
                dataByAssay: [],
                yearAt: moment().year(),
                yearCurrent: moment().year(),
                yearPrevious: moment().year() - 1,
                dateFrom: moment().subtract(1, 'month'),
                dateTo: moment(),
                dateFormat: 'YYYY-MM-DD',
                endOpen: false,
                isSummaryLoading: false,
                isYearlyLoading: false,
                isChartLoaded: false,
            }
        },
 
        methods: {

            async getSummaryStats() {

                this.isSummaryLoading = true;

                this.dataSummary = [];

                var uriSummary = 'stats/by-range?from=' + moment(this.dateFrom).format(this.dateFormat) + '&to=' + moment(this.dateTo).format(this.dateFormat);

                let res = await axios.get(uriSummary, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.summaryStats = res.data.stats;

                var rows = {},
                    temp = [];

                for (const field in this.summaryStats) {
                    console.log(field);
                    if (field != 'filter') {
                        rows[field] = this.summaryStats[field];
                    }
                    temp[0] = rows;
                }

                this.dataSummary = temp;

                console.log('summaryStats');
                console.log(this.summaryStats);

                console.log('dataSummary');
                console.log(this.dataSummary);

                this.isSummaryLoading = false;

            },

            async getYearlyStats() {

                this.isYearlyLoading = true;

                var uriYearly = 'stats/by-month?year=' + this.yearAt;

                let res = await axios.get(uriYearly, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.yearlyStats = res.data.stats;

                var rows = Array(),
                    months = Array(),
                    plots = Array();

                // console.log(res);
                // console.log(this.dataYearly);

                for (const field in this.yearlyStats) {
                    if (field != 'filter' && field != '__sql') {
                        for (var m=0; m < this.yearlyStats[field]['by_month'].length; m++) {
                            rows[this.yearlyStats[field]['by_month'][m]['month']['numeric']] = {};
                            months[m] = this.yearlyStats[field]['by_month'][m]['month']['abbr'];
                        }
                        for (var p=0; p < this.yearlyStats[field]['by_month'].length; p++) {
                            rows[this.yearlyStats[field]['by_month'][p]['month']['numeric']]['key'] = this.yearlyStats[field]['by_month'][p]['month']['numeric'];
                            rows[this.yearlyStats[field]['by_month'][p]['month']['numeric']]['label'] = this.yearlyStats[field]['by_month'][p]['month']['abbr'];
                        }
                        break;
                    }
                }

                for (const field in this.yearlyStats) {
                    if (field != 'filter' && field != '__sql') {
                        plots[field] = {};
                        for (var q=0; q < this.yearlyStats[field]['by_month'].length; q++) {
                            rows[this.yearlyStats[field]['by_month'][q]['month']['numeric']][field] = this.yearlyStats[field]['by_month'][q]['value'];
                            plots[field][q] = this.yearlyStats[field]['by_month'][q]['value'];
                        }
                    }
                }

                var temp = [],
                    z = 0;

                for (const field in rows) {
                    temp[z] = rows[field];
                    z++;
                }

                this.dataYearly = temp;

                // console.log('plots');
                // console.log(plots);

                this.chartData = {
                    type: 'line',
                    data: {
                        labels: months,
                        datasets: [
                            {
                                label: "# Contacts",
                                data: Object.values(plots['num_contacts']),
                                backgroundColor: "rgba(54,73,93,.5)",
                                borderColor: "#36495d",
                                borderWidth: 3
                            },
                        ]
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        scales: {
                        yAxes: [
                            {
                            ticks: {
                                beginAtZero: true,
                                padding: 25
                            }
                            }
                        ]
                        }
                    }
                };

                // console.log('this.chartData');
                // console.log(this.chartData);

                this.isChartLoaded = true;

                this.isYearlyLoading = false;

            },

            handleMonthClick(type, month) {
                console.log(month);
                var via = 'range';
                var from = this.yearAt + '-' + (parseInt(month) < 10 ? '0' + month : month) + '-01';
                var to = moment(from).endOf('month').format(this.dateFormat);
                window.location.href = process.env.BASE_URL + 'account/' + via + '?type=' + type + '&from=' + from + '&to=' + to;
            },

            handleStatsLink(via, type, from, to) {
                console.log('handle stats....');
                window.location.href = process.env.BASE_URL + 'account/' + via + '?type=' + type + '&from=' + from + '&to=' + to;
            },

            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },

            handleEndOpenChange(open) {
                this.endOpen = open;
            },

            handleYearChange(value) {
                this.yearAt = value;
                this.getYearlyStats();
            },

            onChangeDateFrom(date, dateString) {
                this.dateFrom = dateString;
                this.getSummaryStats();
                this.getList();
            },

            onChangeDateTo(date, dateString) {
                this.dateTo = dateString;
                this.getSummaryStats();
                this.getList();
            },

        }

    }
</script>

<style>
.form-container > h3 {margin-bottom:12px !important;}
.date-range-filter {margin-bottom:25px;}
.date-range-filter .span-label {font-size:90%;margin-right:5px;}
.table-stats-summary {margin-bottom:20px;}
.table-stats-summary .ant-pagination {display:none;}
.ant-statistic-title {margin-bottom:0;}
.row-summary-container {max-width:600px;margin-bottom:20px;}
.date-from-container, .date-to-container {display:inline-block;}
.date-from-container {margin-right:20px;}
.row-summary-container .ant-statistic {text-align:left;}
.table-link > a {font-weight:600;}
.table-link > span {color:#999;}
canvas{height:250px;background:#ededed;}
@media only screen and (max-width: 767.99px) {
    .date-from-container, .date-to-container {display:block;margin-bottom:15px;}
    .date-range-filter {margin-bottom:30px;}
    .date-range-filter .span-label {width:90px;display:inline-block;}
    .row-summary-container .ant-statistic {text-align:center;}
}
</style>    