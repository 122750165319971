<template>

    <div>

        <div class="app-page-title ant-page-header" style="margin: 0px 0px 24px;">
            <div class="ant-page-header-heading">
                <span class="ant-page-header-heading-title">
                    <span class="ant-page-header-heading-title-main"></span>
                    <span class="page-title-action">
                        <span>
                            <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="testData.client_order.id" class="num-comments"></a>
                        </span>
                        <span class="vert-divider">|</span>
                        <span><a @click="showModalAddComment">+ add comment</a></span>
                    </span>
                </span>
            </div>
        </div>

        <a-card :bordered="false" style="margin:0 0 24px;">

            <a-row>

                <a-col class="form-container">

                    <a-descriptions
                        title="Order Info"
                        :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                        size="middle"
                        bordered
                    >
                        <a-descriptions-item label="Order ID">
                            <div style="margin-bottom:5px;">
                                {{ testData.client_order.full_id }}
                            </div>
                            <span style="font-size:90%;"><a @click="getRequisitionLink" :data-id="testData.client_order.id"><a-icon type="file-text" /> Requisition</a></span>
                        </a-descriptions-item>
                        <a-descriptions-item label="Report Name">
                        {{ testData.report.label }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Referring Physician">
                        {{ testData.physician.full_name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Date Activated">
                        {{ momentDateFormat(testData.client_order.activated_on) }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Health Questionnaire">
                            <span style="font-size:90%;"><a @click="showModalHq" :data-id="testData.client_order.id" :data-name="testData.individual.full_name"><a-icon type="ordered-list" /> View</a></span>
                        </a-descriptions-item>
                        
                        <a-descriptions-item label="Test status">
                            {{ testData.report.status }}
                            <div v-if="testData.report.is_completed" style="margin-top:5px;">
                                <a-button type="primary" size="small" @click="getReportLink" :data-report_id="testData.report.id">
                                    View Report
                                </a-button>
                            </div>  
                        </a-descriptions-item>
                        <a-descriptions-item label="Action Required">
                            <div v-if="testData.report.is_reviewable">
                                <div style="margin-bottom:5px;">
                                    {{ testData.report.review_status }}
                                </div>
                                <div v-if="testData.report.is_completed">
                                    <div style="margin-bottom:10px;">
                                        <a-button type="danger" size="small" @click="showModalSetStatus" :data-id="testData.client_order.id" :data-report_id="testData.report.id">
                                        Add Record
                                    </a-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!testData.report.is_reviewable">
                                <div v-if="testData.report.is_completed" style="margin-bottom:10px;">
                                    <span v-if="testData.report.review_status_code == 'na'">Contact Not Required</span>
                                    <span v-if="testData.report.review_status_code == 'approved'">Contact Completed</span>
                                </div>
                                <div v-if="!testData.report.is_completed" style="margin-bottom:10px;">
                                    Pending
                                </div>
                            </div>
                            <span style="font-size:90%;"><a v-if="testData.report.num_review_status_logs > 0" :data-id="testData.client_order.id" :data-report_id="testData.report.id" @click="showModalContactHistory"><a-icon type="history" /> History</a></span>
                        </a-descriptions-item>
                        <a-descriptions-item label="Report Release">
                            <div v-if="testData.report.is_released">
                                Released {{ testData.report.released_on }}
                            </div>
                            <div v-if="!testData.report.is_released && !testData.report.is_completed">
                                N/A
                            </div>
                            <div v-if="!testData.report.is_released && testData.report.is_completed">
                                Pending
                            </div>
                        </a-descriptions-item>
                        
                    </a-descriptions>

                    <a-descriptions
                        title="Patient Info"
                        :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
                        size="middle"
                        bordered
                    >
                        <a-descriptions-item label="Patient ID">
                            {{ testData.individual.id }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Patient Name">
                            <a @click="getPatientLink" :data-acct_id="testData.acct.id" :data-id="testData.client_order.id">
                                {{ testData.individual.full_name }}
                                <span v-if="testData.report.first_viewed_at"><a-icon type="eye" style="color:green;font-size:16px;vertical-align:middle;margin-left:3px;" /></span>
                            </a>
                            <div v-if="testData.report.first_viewed_at" style="margin-top:5px;color:green;font-size:80%;font-weight:600;">Viewed results {{ momentDatetimeFormat(testData.report.first_viewed_at) }}</div>
                        </a-descriptions-item>
                        <a-descriptions-item label="Birth Date">
                            {{ momentDateFormat(testData.individual.birth_date) }}
                        </a-descriptions-item>
                    </a-descriptions>

                </a-col>
            </a-row>
            
        </a-card>

        <a-modal
            :title="addCommentTitle"
            class="modal-add-comment"
            v-model="addCommentVisible"
            :visible="addCommentVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="addCommentHandleOk"
            @cancel="addCommentHandleOk"
        >
            <div style="font-weight:600;margin:0 0 5px;font-size:90%;">
                At {{ currentDateTime }}:
            </div>
            <div style="margin:0 0 5px;">
                
                <a-form
                    id="form-comment"
                    :form="formComment"
                    class="comment-form"
                    layout="vertical"
                    @submit.prevent="handleCommentSubmit"
                >
                    
                    <a-form-item
                        style="margin:0;"
                        :help="errors.comment"
                    >
                        <a-textarea
                            v-decorator="[
                            'comment',
                                {
                                    rules: [{ required: true, message: 'Please enter your comment.' }],
                                    initialValue: this.targetComment.comment
                                },
                            ]"
                            placeholder="Add your comment here."
                            :auto-size="{ minRows: 5, maxRows: 10 }"
                        />
                    </a-form-item>

                    <a-form-item
                        style="margin:0 0 15px;font-weight:600;"
                    >
                        <span style="flex:0;margin-right:5px;">
                            <a-checkbox
                                v-model="is_flagged"
                            />
                        </span>
                        <span style="flex:1;">
                            Flag this comment
                        </span>
                    
                    </a-form-item>

                    <a-button :disabled="formCommentSubmitting" :loading="formCommentSubmitting" type="primary" html-type="submit" class="button-submit">
                        {{ formCommentSubmitting ? 'Submitting...' : (this.targetComment.id ? 'Update' : 'Add') + ' Comment' }}
                    </a-button>

                </a-form>

            </div>
        </a-modal>

        <a-modal
            :title="listCommentTitle"
            class="modal-list-comment"
            v-model="listCommentVisible"
            :visible="listCommentVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="listCommentHandleOk"
            @cancel="listCommentHandleOk"
        >
            <div style="overflow-y:scroll;max-height:450px;">
                <a-list item-layout="horizontal" size="large" :data-source="commentData">
                    <a-list-item slot="renderItem" key="item.id" slot-scope="item">
                        <a class="a-edit" v-if="item.is_editable == 1" slot="actions" @click="showModalEditComment" :data-comment_id="item.id">edit</a>
                        <div>
                            <span class="comment-meta">
                                {{ item.created_at | momentDatetime }} by {{ item.from_name }}
                                <a-icon type="flag" v-if="item.is_flagged" theme="filled" :style="{ color: '#cc0000', fontSize: '16px', margin: '0 0 0 7px' }" />
                            </span> 
                            {{ item.comment }}
                        </div>
                    </a-list-item>
                </a-list>
            </div>
        </a-modal>

        <a-modal
            title="Health Questionnaire"
            class="modal-hq"
            v-model="hqVisible"
            :visible="hqVisible"
            :confirm-loading="confirmLoading"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="hqHandleOk"
            @cancel="hqHandleOk"
        >
            <div v-for="(item, index) in hqQuestion" :key="index">
                <div>
                    <span style="font-weight:bold;">{{ item.question.question }}: </span>
                    <span>{{ item.answer.label }}</span>
                </div>
                <a-divider type="horizontal" style="margin:12px 0;" />
            </div>
        </a-modal>

        <a-modal
            title="Contact History"
            v-model="historyVisible"
            :visible="historyVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="historyHandleOk"
            @cancel="historyHandleOk"
            >

            <a-timeline style="overflow-y:scroll; max-height: 400px; margin:-10px;padding:10px;" v-if="historyLog.length > 0">
                <a-timeline-item  v-for="(item, index) in historyLog" :key="index">
                    <div style="font-weight:bold;margin-bottom:2px;">
                        {{ momentDatetimeFormat(item.recorded_at) }}:
                        <span v-if="item.is_contact_successful == '1'">Sucessful Contact</span>
                        by {{item.recorded_by}}
                    </div>
                    {{ item.comment }}
                    <div style="padding-top:5px;" v-if="item.from_status != item.to_status">Status Change: {{ item.to_status }}</div>
                </a-timeline-item>
            </a-timeline>

            <div v-if="historyLog.length == 0">
                No history found.
            </div>
            
        </a-modal>

    </div>

</template>

<script>

    import axios from '../../api'
    import moment from 'moment'

    export default {

        name: 'Test',

        components: {
            
        },

        filters: {
            moment: function (date) {
                return moment(date).format('MMM D, YYYY');
            },
            momentDatetime: function (date) {
                return moment(date).format('MMM D, YYYY HH:mm:ss');
            }
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken}
        },

        mounted: function() {

            this.getTest();

            this.$nextTick(function () {
                // console.log('section: ' + this.section);
                // console.log('reviewStatus: ' + this.reviewStatus);
            })
        },

        beforeCreate() {
            this.formComment = this.$form.createForm(this, { name: 'normal_comment' });
        },

        data: function() {
            return {
                error: '',
                errors: {},
                historyLog: [],
                hqQuestion: [],
                orderData: [],
                testData: {
                    report: {},
                    product: {},
                    physician: {},
                    client_order: {},
                    sample_kit: {},
                    individual: {},
                    results: {},
                    acct: {}
                },
                targetComment: {
                    id: '',
                    comment: '',
                    is_flagged: false
                },
                numComments: {},
                commentData: [],
                currentDateTime: moment().format('lll'),
                validateStatus: '',
                addCommentTitle: '',
                listCommentTitle: '',
                is_flagged: false,
                confirmLoading: false,
                isTestLoaded: false,
                hqVisible: false,
                historyVisible: false,
                addCommentVisible: false,
                listCommentVisible: false,
                formCommentSubmitting: false
            }
        },
 
        methods: {

            async getTest() {

                this.isTestLoaded = true;

                var uriTest = 'reports/' + this.$route.params.report_id;

                let res = await axios.get(uriTest , {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.testData = res.data.report;

                console.log('this.testData');
                console.log(this.testData);

                this.getPageTitle();
                this.getNumComments();

                this.isTestLoaded = false;

            },

            async getPageTitle() {

                var title = 'Test - ' + this.testData.product.label + ' (ID: ' + this.testData.client_order.full_id + ')';

                document.getElementsByClassName('ant-page-header-heading-title-main')[0].innerHTML = title;

            },

            async getNumComments() {

                var uriCommentCount = 'comments/count?client_order_id=' + this.testData.client_order.id;

                let res = await axios.get(uriCommentCount , {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.numComments = res.data.count;

                console.log('this.comments');
                console.log(this.numComments);

                if (parseInt(this.numComments.flagged) > 0) {
                    document.getElementsByClassName('num-comments')[0].classList.add('alert');
                }

                document.getElementsByClassName('num-comments')[0].innerHTML = this.numComments.all + ' comment' + (parseInt(this.numComments.all) > 1 ? 's' : '') + (parseInt(this.numComments.flagged) > 0 ? ' (' + parseInt(this.numComments.flagged) + ' flagged)' : '');

            },

            async handleCommentSubmit() {

                // console.log('submitting comment form.');

                try {

                    this.formCommentSubmitting = true;

                    const firstCommentRes = await this.formComment.validateFields();

                    if (firstCommentRes) {

                        console.log(firstCommentRes.comment);
                        console.log(this.is_flagged);
                        console.log(this.testData.client_order.id);

                        var uriPostComment = '';

                        if (this.targetComment.id) {
                            uriPostComment = 'comments/' + this.targetComment.id
                        }
                        else {
                            uriPostComment = 'comments?client_order_id=' + this.testData.client_order.id;
                        }
                        
                        const commentRes = await axios.post(uriPostComment, {
                            comment: firstCommentRes.comment,
                            is_flagged: this.is_flagged
                        }, {headers: {
                            'Authorization': 'Bearer ' + this.token
                        } } );

                        if (commentRes) {
                            console.log('success!');
                            console.log(commentRes);
                            this.error = '';
                            this.errors = {};
                            this.validateStatus = '';
                            
                            this.formCommentSubmitting = false;
                            this.addCommentVisible = false;

                            this.$message.success('Your comment has been ' + (this.targetComment.id ? 'updated' : 'added') +' successfully.', 5);

                            this.getNumComments();
                            
                        }

                    }

                } catch(e) {
                    this.formCommentSubmitting = false;
                    var err = e.response.data.error;
                    this.error = err;
                    this.errors = e.response.data.errors;
                    this.validateStatus = 'error';
                }
                
            },

            historyHandleOk() {
                this.historyVisible = false;
            },

            getPatientLink: async function(event) {
                var acctId = event.target.getAttribute('data-acct_id');
                window.location.href = process.env.BASE_URL + 'account/patient/' + acctId;
            },

            getTestLink: async function(event) {
                var reportId = event.target.getAttribute('data-report_id');
                window.location.href = process.env.BASE_URL + 'account/test/' + reportId;
            },

            getRequisitionLink: async function(event) {
                var orderId = event.target.getAttribute('data-id');
                window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/requisition?access_token=' + this.token, '_blank');
            },

            getReportLink: async function(event) {
                var reportId = event.target.getAttribute('data-report_id');
                window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId + '/pdf?access_token=' + this.token, '_blank'); 
            },

            showModalAddComment: async function() {
                this.targetComment.comment = '';
                this.targetComment.id = '';
                this.targetComment.is_flagged = false;
                this.formComment.resetFields('comment'); 
                this.getNumComments();

                var modalTitle = 'Order ID ' + this.testData.client_order.full_id;
                this.addCommentTitle = 'Add Comment: ' + modalTitle;

                this.listCommentVisible = false;
                this.addCommentVisible = true;
            },

            showModalEditComment: async function(event) {
                var commentId = event.target.getAttribute('data-comment_id');
                console.log('targeted comment_id: ' + commentId);

                let res = await axios.get('comments/' + commentId, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                console.log('edit-comment');
                console.log(res.data);

                this.addCommentTitle = 'Edit Comment ID: ' + commentId;

                this.targetComment = res.data.comment;
                this.is_flagged = this.targetComment.is_flagged;

                this.listCommentVisible = false;
                this.addCommentVisible = true;
            },

            showModalListComment: async function(event) {
                this.addCommentVisible = false;
                this.listCommentVisible = true;

                var modalTitle = event.target.getAttribute('data-comment_type') == 'acct_id' ? 'Patient ID ' + event.target.getAttribute('data-comment_type_id') : this.testData.product.label + ' (ID: ' + this.testData.client_order.full_id + ')';

                this.listCommentTitle = 'List Comment: ' + modalTitle;

                let res = await axios.get('comments?' + event.target.getAttribute('data-comment_type') + '=' + event.target.getAttribute('data-comment_type_id'), {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                console.log('list-comment');
                console.log(res.data.comments);

                this.commentData = res.data.comments;

            },

            showModalHq: async function(event) {
                this.hqVisible = true;
                var orderId = event.target.getAttribute('data-id');

                let res = await axios.get('orders/' + orderId + '/health-questionnaire', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                // console.log('......showModalHq........');
                // console.log(res.data.health_questionnaire);
                // console.log(res.data.health_questionnaire.question);
                // console.log(res.data.health_questionnaire.answer);

                this.hqQuestion = res.data.health_questionnaire;

            },

            showModalContactHistory: async function(event) {
                
                this.historyVisible = true;

                var reportId = event.target.getAttribute('data-report_id');
                
                let res = await axios.get('reports/' + reportId + '/review-status-logs', {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                console.log('......showModalContactHistory........');
                console.log(reportId);
                console.log(res.data.logs);

                this.historyLog = res.data.logs;

            },

            addCommentHandleOk() {
                this.addCommentVisible = false;
            },

            listCommentHandleOk() {
                this.listCommentVisible = false;
            },

            hqHandleOk() {
                this.hqVisible = false;
            },

            momentDatetimeFormat(args) {
                return moment(args).format('MMM DD, YYYY HH:mm:ss');
            },

            momentDateFormat(args) {
                return moment(args).format('MMM DD, YYYY');
            },

        }

    }
</script>

<style scoped>
.comment-meta {display:block;font-size:12px;font-weight:600;color:#000;margin:0 0 3px;}
.ant-list-items > li:first-child {padding-top:0;}
.a-edit {font-size:90%;}
</style>