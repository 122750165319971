<template>

    <div>

        <a-page-header v-if="isLoggedIn"
            style="margin:0 0 24px;"
            class="app-page-title"
            :ghost="false"
            title=" "
        />

        <div style="background: rgb(255, 255, 255);margin:0 0 24px;padding:30px;">

            <a-table
                :scroll="{ x: 1000 }"
                :columns="dataCommentColumns"
                :rowKey="record => record.id"
                :dataSource="commentList"
                :pagination="pagination"
                :loading="isCommentLoading"
                @change="handleTableChange"
                >
                
                <div slot="dateCreated" slot-scope="text">{{ momentDatetimeFormat(text) }}</div>
                <div slot="toLabel" slot-scope="text, record">
                    <span v-if="record.to_module == 'client_order'">
                        <a @click="getTestLink" :data-id="record.to_id">{{ text }}</a>
                    </span>
                    <span v-else>
                        <a @click="getPatientLink" :data-id="record.to_id">{{ text }}</a>
                    </span>
                </div>
                <div slot="isFlagged" slot-scope="text">
                    <a-icon type="flag" v-if="text" theme="filled" :style="{ color: '#cc0000', fontSize: '16px', margin: '0 0 0 7px' }" />
                </div>
            </a-table>
        </div>
        

    </div>

</template>

<script>

    import axios from '../../api'   
    // import Error from '../Error.vue'
    import moment from 'moment'
    // import Chart from 'chart.js'

    const dataCommentColumns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            scopedSlots: {
                customRender: "dateCreated"
            }
        },
        {
            title: 'By',
            dataIndex: 'from_name',
        },
        {
            title: 'For',
            dataIndex: 'to_label',
            scopedSlots: {
                customRender: "toLabel"
            }
        },
        {
            title: 'Flagged?',
            dataIndex: 'is_flagged',
            scopedSlots: {
                customRender: "isFlagged"
            },
            width: "10%",
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            width: "50%",
        }
    ];

    export default {

        name: 'Comment',

        components: {
            // Error
        },

        computed: {
            // ...mapGetters(['getUser']),
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            dateFromFormatted: function() {
                return moment(this.dateFrom).format(this.dateFormat);
            },
            dateToFormatted: function() {
                return moment(this.dateTo).format(this.dateFormat);
            }
        },

        mounted: function() {

            this.getCommentListRequest();
            
            this.$nextTick(function () {
                // console.log('section: ' + this.section);
                // console.log('reviewStatus: ' + this.reviewStatus);
            })
        },

        data: function() {
            return {
                dataCommentColumns,
                error: '',
                errors: {},
                commentList: [],
                pagination: {},
                yearlyStats: {},
                dataSummary: [],
                dataYearly: [],
                yearAt: moment().year(),
                yearCurrent: moment().year(),
                yearPrevious: moment().year() - 1,
                dateFrom: moment().subtract(1, 'month'),
                dateTo: moment(),
                dateFormat: 'YYYY-MM-DD',
                endOpen: false,
                isCommentLoading: false,
                isYearlyLoading: false,
                isChartLoaded: false,
            }
        },
 
        methods: {

            async getCommentListRequest() {

                this.isCommentLoading = true;

                this.dataSummary = [];

                var uriSummary = 'comments/all?' + (this.pagination.current === undefined ? '' : '&page=' + this.pagination.current);

                let res = await axios.get(uriSummary, {
                    headers: { 'Authorization': 'Bearer ' + this.token }
                });

                this.commentList = res.data.comments;

                this.pagination = {
                    ...this.pagination,
                    size: 'small',
                    pageSize: res.data.pagination.item_count_per_page,
                    total: res.data.pagination.total_item_count,
                    showTotal: (num, range) => range[0] + ' to ' + range[1] + ' of ' + num + ' Comments'
                };

                console.log('.....getCommentListRequest......');
                console.log(this.commentList);

                this.isCommentLoading = false;

            },

            handleTableChange(pagination, filters, sorter) {
                this.pagination = {
                    ...this.pagination,
                    current: pagination.current
                };

                this.getCommentListRequest({
                    results: pagination.pageSize,
                    page: pagination.current,
                    sortField: sorter.field,
                    sortOrder: sorter.order,
                    ...filters
                });
            },

            handleMonthClick(type, month) {
                console.log(month);
                var via = 'range';
                var from = this.yearAt + '-' + (parseInt(month) < 10 ? '0' + month : month) + '-01';
                var to = moment(from).endOf('month').format(this.dateFormat);
                window.location.href = process.env.BASE_URL + 'account/' + via + '?type=' + type + '&from=' + from + '&to=' + to;
            },

            handleStatsLink(via, type, from, to) {
                window.location.href = process.env.BASE_URL + 'account/' + via + '?type=' + type + '&from=' + from + '&to=' + to;
            },

            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },

            handleEndOpenChange(open) {
                this.endOpen = open;
            },

            handleYearChange(value) {
                this.yearAt = value;
            },

            onChangeDateFrom(date, dateString) {
                this.dateFrom = dateString;
                this.getCommentListRequest();
            },

            onChangeDateTo(date, dateString) {
                this.dateTo = dateString;
                this.getCommentListRequest();
            },

            momentDatetimeFormat(args) {
                return moment(args).format('MMM DD, YYYY HH:mm:ss');
            },

            getPatientLink: async function(event) {
                var acctId = event.target.getAttribute('data-id');
                window.location.href = process.env.BASE_URL + 'account/patient/' + acctId;
            },

            getTestLink: async function(event) {
                var reportId = event.target.getAttribute('data-id');
                window.location.href = process.env.BASE_URL + 'account/test/' + reportId;
            },

        }

    }
</script>

<style>
.form-container > h3 {margin-bottom:12px !important;}
.date-range-filter {margin-bottom:25px;}
.date-range-filter .span-label {font-size:90%;margin-right:5px;}
.table-stats-summary {margin-bottom:20px;}
.table-stats-summary .ant-pagination {display:none;}
.ant-statistic-title {margin-bottom:0;}
.row-summary-container {max-width:600px;}
.date-from-container, .date-to-container {display:inline-block;}
.date-from-container {margin-right:20px;}
.row-summary-container .ant-statistic {text-align:left;}
.table-link > a {font-weight:600;}
.table-link > span {color:#999;}
canvas{height:250px;background:#ededed;}
@media only screen and (max-width: 767.99px) {
    .date-from-container, .date-to-container {display:block;margin-bottom:15px;}
    .date-range-filter {margin-bottom:30px;}
    .date-range-filter .span-label {width:90px;display:inline-block;}
    .row-summary-container .ant-statistic {text-align:center;}
}
</style>    