var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',[_c('a-col',{staticClass:"app-login"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),(_vm.$route.query.redirect)?_c('a-alert',{staticStyle:{"margin-bottom":"20px"},attrs:{"description":"Your session has expired, please login again.","type":"info","show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"form-normal-login","form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    { rules: [{ required: true, message: 'Please input your email!' }] },
                    ]),expression:"[\n                    'email',\n                    { rules: [{ required: true, message: 'Please input your email!' }] },\n                    ]"}],attrs:{"placeholder":"johndoe@mail.com","size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    { rules: [{ required: true, message: 'Please input your Password!' }] },
                    ]),expression:"[\n                    'password',\n                    { rules: [{ required: true, message: 'Please input your Password!' }] },\n                    ]"}],attrs:{"type":"password","placeholder":"Password","size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" Log in ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }