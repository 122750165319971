import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_REPH_URL_GFRONT,
  headers: {
    "Content-Type": "application/json",
  },
});

console.log('axiosInstance');
console.log(axiosInstance);
console.log(process.env.VUE_APP_API_REPH_URL_GFRONT);

export default axiosInstance;