import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faExclamationTriangle, faQuestionCircle, faCheckCircle, faSpinner, faUserCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'
import store from './vuex'

// import axios from 'axios'
import moment from 'moment'

import axiosInstance from './api'

// import setupInterceptors from './interceptors';
// setupInterceptors(store);

library.add(faUserSecret, faExclamationTriangle, faQuestionCircle, faCheckCircle, faSpinner, faUserCircle, faShoppingCart);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.$http = axiosInstance;

axiosInstance.defaults.timeout = 20000;

axiosInstance.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    // const token = this.$store.getters.getToken;
    if (token) {
      // config.headers.common["Authorization"] = token;
      config.headers["Authorization"] = 'Bearer ' + token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
async (error) => {
  console.log('error ' + error.response.status);
  console.log(error);
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          // alert('Error ' + error.response.status + ' detected.');
          break;
        case 401:
//           alert('Error ' + error.response.status + ': session expired.');
          break;
        case 403:
          
          try {
            
            if (store.getters.getRefreshToken) {
              const response = await axiosInstance.post("auth/refresh", {
                refresh_token: store.getters.getRefreshToken,
              });

              // console.log('response for /reph/auth/refresh:');
              // console.log(response);
              // console.log(response.data);

              store.dispatch('setToken', response.data.access_token);
              store.dispatch('setRefreshToken', response.data.refresh_token);

              // console.log('getRefreshToken: ' + store.getters.getRefreshToken);

              router.go();

            }
            
          }
          catch (_error) {
            // return Promise.reject(_error);
            console.log('error: /reph/auth/refresh not working.');
            store.dispatch('setUser', {});
            store.dispatch('setToken', null);
            store.dispatch('setRefreshToken', null);
            router.push('/login');
          }

          break;
        case 404:
          alert('Error ' + error.response.status + ': page does not exist.');
          break;
        case 502:
          alert('Error ' + error.response.status + ' detected.');
          setTimeout(() => {
            router.push('/login');
          }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);



// axios.defaults.baseURL = process.env.VUE_APP_API_REPH_URL_GFRONT;
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

Vue.use(Antd);
Vue.use(moment);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
