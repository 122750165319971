<template>
  <div id="app" class="components-layout-demo-basic">
    <a-layout>
      <Nav />
      <a-layout-content class="app-main">
        <router-view />
      </a-layout-content>
      <a-layout-footer class="app-footer">
        &copy; {{ new Date().getFullYear() }} All Rights Reserved.
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>

import Nav from './components/Nav.vue'
// import VertNav from './components/VertNav.vue'
// import axios from 'axios'

export default {
  name: 'App',
  components: {
    Nav
  },
  created () {
    document.title = 'Physician Console';
  }
  /* async created() {
    const response = await axios.get('user');
    this.$store.dispatch('user', response.data);
  } */
}
</script>

<style>
html, body, #app, .ant-layout, #user-console { 
  height:100%;
  margin:0;
}
.ant-input {
  height:40px;
}
.app-main {
  background-color:#fff !important;
  flex: 1 0 auto !important;
}
.app-footer {
  font-size:12px !important;
  border-top:1px solid #ededed;
  background-color:#fff !important;
  height:70px;
  width:100%;
  height:auto;
  margin:0;
  display:flex;
  justify-content:center;
  background-color:#f4f4f4;
}

</style>