import Vue from 'vue'
import Router from 'vue-router'
import Account from './components/Account.vue'
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import Login2fa from './components/Login2fa.vue'
// import Register from './components/Register.vue'
// import Forgot from './components/Forgot.vue'
// import Reset from './components/Reset.vue'

import store from './vuex'

const ifNotAuthenticated = (to, from, next) => {
  if (Object.keys(store.getters.getToken ?? {}).length === 0) {
    next()
    return
  }
  next('/account')
}

const ifAuthenticated = (to, from, next) => {
  // console.log(store.getters.getToken ? 'true' : 'false');
  // console.log(Object.keys(store.getters.getToken ?? {}).length);
  if (Object.keys(store.getters.getToken ?? {}).length !== 0) {
    next()
    return
  }
  next('/login')
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Default Home (Temporary)',
            component: Home,
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
        },
        {
            path: '/account',
            name: 'Account',
            component: Account,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/account/:section',
            name: 'Account Settings',
            component: Account,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/account/patient/:acct_id',
            name: 'Patient',
            component: Account,
            meta: {
              section: 'patient'
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/account/test/:report_id',
            name: 'Test',
            component: Account,
            meta: {
              section: 'test'
            },
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/login-2fa',
            name: 'Login2fa',
            component: Login2fa,
            beforeEnter: ifNotAuthenticated,
        },
    ]
})