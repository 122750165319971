<template>
    <div>
        <a-row>
            <a-col class="app-login">

              <h1>Two-Factor Authentication</h1>

              <p>Enter the 6-character code sent to your Email address.</p>

                <error v-if="error" :error="error" />

                <a-alert
                  v-if="$route.query.redirect"
                  description="Your session has expired, please login again."
                  type="info"
                  show-icon
                  style="margin-bottom:20px;"
                />

                <a-alert
                    v-if="$route.query.resend"
                    description="A new code has been sent. Please check your Email."
                    type="info"
                    show-icon
                    style="margin-bottom:20px;"
                  />

                <a-form
                      id="form-normal-login-2fa"
                      :form="form"
                      class="login-2fa-form"
                      layout="vertical"
                      @submit.prevent="handle2faSubmit"
                  >

                      <a-form-item>
                          <vue-fake-input
                            :length="6"
                            :fontSize="40"
                            inputColor="#00b203"
                            fontColor="#333333"
                            :allowPaste="true"
                            :onlyNumber="true"
                            v-model="vCode"
                          />
                      </a-form-item>

                      <a-form-item style="margin-bottom:0;">

                          <div style="margin-bottom:20px;">
                            <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="login-form-button" size="large">
                              {{ formSubmitting ? 'Submitting...' : 'Submit Verification Code' }}
                            </a-button>
                          </div>

                          <div style="text-align:center;font-size:12px;">Haven't received it? <a @click="resendCode">Re-send a new code.</a></div>

                      </a-form-item>
                    
                  </a-form>

            </a-col>
        </a-row>
    </div>
</template>


<script>
    import axios from '../api'
    import Error from './Error.vue'
    import VueFakeInput from 'vue-fake-input';

    export default {
        name: 'Login2fa',
        components: {
            Error,
            VueFakeInput
        },
        data() {
            return {
                vCode: '',
                error: '',
                formSubmitting: false
            }
        },
        beforeCreate() {
          this.form = this.$form.createForm(this, { name: 'normal_login' });
        },
        computed: {
          // ...mapGetters(['getUser']),
          isLoggedIn: function () { return this.$store.getters.isAuthenticated },
          user: function () { return this.$store.getters.getUser },
        },
        methods: {

            async handle2faSubmit() {

                try {

                  this.formSubmitting = true;

                  console.log('vCode -> ' + this.vCode);

                  var elem = document.querySelector('.ant-alert-success');
                  if (typeof (elem) != 'undefined' && elem != null) {
                    elem.parentNode.removeChild(elem);
                  }

                  var inputs = document.getElementsByTagName('input');

                  this.vCode = '';

                  for (var i = 0; i < inputs.length; i++) {
                    if (inputs[i].type.toLowerCase() == 'text') {
                      console.log(i + ': ' + inputs[i].value);
                      this.vCode += inputs[i].value;
                    }
                  }
                  
                  console.log('this.vCode -> ' + this.vCode);

                  const response = await axios.post('auth/verify-2fa', {
                    reph_login_id: this.user.reph_login_id,
                    tfa_code: this.vCode
                  });

                  this.error = '';
                  this.$store.dispatch('setUser', response.data.user);
                  this.$store.dispatch('setToken', response.data.access_token);
                  this.$store.dispatch('setRefreshToken', response.data.refresh_token);

                  if (this.$route.query.redirect && this.$route.query.redirect != '') {
                    this.$router.push(this.$route.query.redirect);
                  }
                  else {
                    this.$router.push('/account/all');
                  }

                } catch (e) {

                  console.log(e.data.error);
                  this.formSubmitting = false;
                  this.error = e.data.error;
                }

              },

              async resendCode() {

                console.log('resent');

                try {

                  const response = await axios.post('auth/request-2fa', {
                    reph_login_id: this.user.reph_login_id
                  });

                  console.log(response);

                  this.error = '';
                  //this.$store.dispatch('setUserId', response.data.reph_login_id);
                  // this.$store.dispatch('setToken', response.data.access_token);
                  // this.$store.dispatch('setRefreshToken', response.data.refresh_token);

                  this.$router.push('/login-2fa?resend=1');

                } catch (e) {

                  console.log('error.');
                  console.log(e);
                  this.error = 'Invalid re-send request.';
                }

              }

            }
    }
</script>

<style>
.ant-form-item, .ant-form label {
  font-size:12px;
}
.ant-input-lg {
  font-size:12px;
}
.app-login {
  max-width: 460px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.ant-form-explain {
  font-size:12px;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-login-2fa .login-form {
  max-width: 300px;
}
#form-normal-login-2fa .login-form-forgot {
  float: right;
}
#form-normal-login-2fa .login-form-button {
  margin:10px 0 5px;
  width: 100%;
}
.fk-input-container input {
  width:61px !important;
}
</style>