<template>
    <div id="user-console">
        <a-layout>

            <a-layout-sider
                style="background: #fff"
                breakpoint="md"
                collapsed-width="0"
                v-model="appSidebar"
                @collapse="onSiderCollapse"
                @breakpoint="onSiderBreakpoint"
                :zeroWidthTriggerStyle="{
                    'position': 'absolute',
                    'left': 'calc(100vw - 50px)',
                    'z-index': '10',
                    'width': '36px',
                    'font-size': '24px',
                    'color': '#000',
                    'background': 'none'
                }"
            >
                <a-row class="vert-nav-header">
                <a-row
                    type="flex"
                    justify="center"
                    align="middle"
                    style="margin:0 0 10px;"
                >
                    <span class="ant-avatar ant-avatar-circle ant-avatar-icon" style="width: 20px; height: 20px; line-height: 20px; font-size: 10px; vertical-align: middle; background-color: rgb(207, 208, 220);">
                    <span role="img" aria-label="user" class="anticon anticon-user" style="color: rgb(49, 57, 109);">
                        <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="user" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                        </svg>
                    </span>
                    </span>

                    <a-row
                    class="typography-ellipsis"
                    style="margin-left:5px;"
                    >
                    {{ user.first_name }} {{ user.last_name }}
                    </a-row>

                </a-row>

                <a-row
                    type="flex"
                    justify="center"
                    align="middle"
                    style="width:90%;margin:0 0 15px;"
                >
                    <div class="typography-ellipsis">
                    {{ user.email }}
                    </div>

                </a-row>
                
                <a-row>
                    <a @click="handleLogoutClick"><a-icon type="logout" /> Logout </a>
                </a-row>


                </a-row>
                    <a-menu
                        mode="inline"
                        :default-selected-keys="[section]"
                        :default-open-keys="[sectionParent]"
                        :style="{ borderRight: 0 }"
                    >
                    <a-sub-menu key="dashboard" class="app-vert-menu">
                        <span slot="title" style="width: 100%; height: 40px; color: rgb(49, 57, 109); font-weight: 600; font-size: 0.9rem; text-decoration: underline rgb(89, 126, 247); text-underline-position: under;  ">
                        Patient Tests
                        </span>
                        <a-menu-item key="required" @click="handleMenuClick">
                        Requires Contact<span v-if="countData.required > 0"> ({{ countData.required }})</span>
                        </a-menu-item>
                        <a-menu-item key="received" @click="handleMenuClick">
                        Testing in Progress<span v-if="countData.received > 0"> ({{ countData.received }})</span>
                        </a-menu-item>
                        <a-menu-item key="pending" @click="handleMenuClick">
                        Awaiting Sample<span v-if="countData.pendiing > 0"> ({{ countData.pendiing }})</span>
                        </a-menu-item>
                        <a-menu-item key="approved" @click="handleMenuClick">
                        Contact Completed
                        </a-menu-item>
                        <a-menu-item key="state_reporting_missing" @click="handleMenuClick">
                        Missing State Reporting<span v-if="countData.state_reporting_missing > 0"> ({{ countData.state_reporting_missing }})</span>
                        </a-menu-item>
                        <a-menu-item key="na" @click="handleMenuClick">
                        Contact Not Required
                        </a-menu-item>
                        <a-menu-item key="all" @click="handleMenuClick">
                        All Tests
                        </a-menu-item>
                        <a-menu-item key="stats" @click="handleMenuClick">
                        Statistics
                        </a-menu-item>
                        <a-menu-item key="state-reporting-logs" @click="handleMenuClick">
                        State Reporting Logs
                        </a-menu-item>
                        <a-menu-item key="comment" @click="handleMenuClick">
                        Comments<span v-if="commentCountData.all > 0"> ({{ commentCountData.all }})</span>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-divider style="margin:10px 0;" />
                    <a-sub-menu key="setting" class="app-vert-menu">
                        <span slot="title" style="width: 100%; height: 40px; color: rgb(49, 57, 109); font-weight: 600; font-size: 0.9rem; text-decoration: underline rgb(89, 126, 247); text-underline-position: under;  ">
                        Settings
                        </span>
                        <a-menu-item key="security" @click="handleMenuClick">
                            Security and Login
                        </a-menu-item>
                    </a-sub-menu>
                    <a-divider style="margin:10px 0;" />
                </a-menu>

            </a-layout-sider>
            
            <a-layout-content
            :style="{ padding: '24px', margin: 0, minHeight: 'auto' }"
            >   

                <div v-if="section != 'security' && section != 'stats' && section != 'state-reporting-logs' && section != 'patient' && section != 'test' && section != 'comment'">
                    
                    <div style="background: rgb(255, 255, 255);min-height: auto;margin:0 0 24px;">
                        <a-page-header v-if="isLoggedIn"
                            class="app-page-title"
                            :ghost="false"
                            title=" "
                        />
                        <a-page-header v-if="!isLoggedIn"
                            style=""
                            class="app-page-title"
                            :ghost="false"
                            title="You are not logged in!"
                        />
                    </div>

                    <div style="background: rgb(255, 255, 255);margin:0 0 24px;padding:30px;">

                        <a-form-model
                            class="div-select-physician"
                            ref="formPatientSearch"
                            layout="inline"
                            @submit.prevent="handlePatientSubmit"
                        >
                            <a-form-model-item>
                                <a-select default-value="" @change="handlePhysicianChange">
                                    <a-select-option value="">All Physicians</a-select-option>
                                    <a-select-option v-for="physician in physicianData" :key="physician.key">
                                        {{ physician.value }} ( {{ physician.key }} )
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item>
                                <a-select default-value="" @change="handleReportableChange" class="shorter">
                                    <a-select-option value="">All Reports</a-select-option>
                                    <a-select-option value="0">Not Reportable</a-select-option>
                                    <a-select-option value="1">Reportable</a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item>
                                <a-select
                                    show-search
                                    default-value=""
                                    @change="handleProductChange"
                                    class="longer"
                                    :filter-option="filterOption"
                                    option-filter-prop="children"
                                >
                                    <a-select-option value="">All Tests</a-select-option>
                                    <a-select-option v-for="product in productData" :key="product.key">
                                        {{ product.value }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item>
                                <a-select
                                    show-search
                                    default-value=""
                                    @change="handleAssayChange"
                                    class="longer"
                                    :filter-option="filterOption"
                                    option-filter-prop="children"
                                >
                                    <a-select-option value="">All Assays</a-select-option>
                                    <a-select-option v-for="assay in assayData" :key="assay.key">
                                        {{ assay.value }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>

                            <a-form-model-item>
                                <a-input-group compact>
                                    <a-input v-if="formPatientSearch.type != 'birth_date'" v-model="formPatientSearch.q" style="width:55%;" placeholder="Search for Patient(s)..." />
                                    <a-date-picker v-if="formPatientSearch.type == 'birth_date'" v-model="formPatientSearch.d" placeholder="Select Date (YYYY-MM-DD)" style="width:55%;" @change="onChangePatientDob" />
                                    <a-select v-model="formPatientSearch.type" style="width:35%" @change="onChangeSearchType">
                                        <a-select-option value="name">
                                        by Name
                                        </a-select-option>
                                        <a-select-option value="birth_date">
                                        by Date of Birth
                                        </a-select-option>
                                        <a-select-option value="phone">
                                        by Phone Number
                                        </a-select-option>
                                        <a-select-option value="email">
                                        by Email
                                        </a-select-option>
                                        <a-select-option value="client_order_id">
                                        by Order ID
                                        </a-select-option>
                                    </a-select>
                                    <a-button type="primary" html-type="submit" class="status-form-button" style="width:10%;"><a-icon type="search" /></a-button>
                                </a-input-group>
                            </a-form-model-item>
                        </a-form-model>

                        <a-table
                        :scroll="{ x: 1000 }"
                        :columns="renderedColumns"
                        :rowKey="record => record.order.id"
                        :dataSource="data"
                        :pagination="pagination"
                        :loading="loading"
                        @change="handleTableChange"
                        >
                        
                            <div slot="dateActivated" slot-scope="text, record">
                                <div style="margin-bottom:3px;">
                                    {{ text }}
                                </div>
                                <a @click="getTestLink" :data-report_id="record.report.id">{{ record.order.full_id }}</a>
                            </div>
                            <div slot="name" slot-scope="text, record">
                                <!-- <a @click="showModalPatient" :data-acct_id="record.acct.id" :data-id="record.order.id">{{ text }} <span v-if="record.report.first_viewed_at"><a-icon type="eye" style="color:green;font-size:16px;vertical-align:middle;margin-left:3px;" /></span></a> -->
                                <div style="margin-bottom:8px;">
                                    <a @click="getPatientLink" :data-acct_id="record.acct.id" :data-id="record.order.id">
                                        {{ text }}
                                    </a>
                                    <span v-if="record.report.first_viewed_at"><a-icon type="eye" style="color:green;font-size:16px;vertical-align:middle;margin-left:3px;" /></span>

                                    <div v-if="record.report.first_viewed_at" style="margin-top:5px;color:green;font-size:90%;font-weight:600;">Viewed results {{ momentDatetimeFormat(record.report.first_viewed_at) }}</div>
                                </div>
                                <a @click="showModalHq" :data-id="record.order.id" :data-name="record.individual.full_name"><a-icon type="ordered-list" /> Questionnaire</a>
                            </div>
                            <div slot="product" slot-scope="text, record">
                                <div style="margin-bottom:8px;">
                                    {{ text }}  
                                </div>
                                <a @click="getRequisitionLink" :data-id="record.order.id"><a-icon type="file-text" /> Requisition</a>
                            </div>
                            <div slot="assays" slot-scope="record">
                                <div v-for="(caption, key) in record" :key="key">
                                    {{ caption }}<br />
                                </div>
                            </div>
                            <div slot="numComments" slot-scope="text, record">
                                <div v-if="record.order.num_all_comments == 0">
                                    {{ record.order.num_all_comments }}
                                </div>
                                <div v-else>
                                    <div v-if="record.order.num_flagged_comments == 0">
                                        <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="record.order.id" :data-comment_type_label="record.product.name" :data-comment_type_full_id="record.order.full_id" class="num-comments">{{ record.order.num_all_comments }} comment<span v-if="record.order.num_all_comments > 1">s</span></a>
                                    </div>
                                    <div v-else>
                                        <a @click="showModalListComment" data-comment_type="client_order_id" :data-comment_type_id="record.order.id" :data-comment_type_label="record.product.name" :data-comment_type_full_id="record.order.full_id" class="alert num-comments">{{ record.order.num_all_comments }} comment<span v-if="record.order.num_all_comments > 1">s</span><br />({{ record.order.num_flagged_comments }} flagged)</a>
                                    </div>
                                </div>
                            </div>  
                            <div slot="testStatus" slot-scope="text, record">
                                {{ text }}
                                <div v-if="record.report.is_released">
                                    <hr style="margin:5px 0;" />
                                    Report Released {{ record.report.released_on }}
                                </div>
                                <div v-if="!record.report.is_released && !record.report.is_completed">
                                    
                                </div>
                                <div v-if="!record.report.is_released && record.report.is_completed">
                                    <hr style="margin:5px 0;" />
                                    Report Pending
                                </div>

                                <div v-if="record.report.is_completed" style="margin-top:5px;">
                                    <hr style="margin:5px 0 15px;" />
                                    <a-button type="primary" size="small" @click="getReportLink" :data-report_id="record.report.id">
                                        View Report
                                    </a-button>
                                </div>  
                            </div>
                            <div slot="physicianAssigned" slot-scope="text">
                                {{ text }}
                            </div>
                            <div slot="physicianActionRequired" slot-scope="text, record">
                                <div v-if="record.report.is_reviewable">
                                    <div style="margin-bottom:5px;">
                                        {{ record.report.review_status }}
                                    </div>
                                    <div v-if="record.report.is_completed">
                                        <div style="margin-bottom:10px;">
                                            <a-button type="danger" size="small" @click="showModalSetStatus" :data-id="record.order.id" :data-report_id="record.report.id" :data-division_name="record.order.division_name" :data-is_contact_attempted="record.report.is_contact_attempted">
                                                Add Record
                                            </a-button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!record.report.is_reviewable">
                                    <div v-if="record.report.is_completed" style="margin-bottom:10px;">
                                        <span v-if="record.report.review_status_code == 'na'">Contact Not Required</span>
                                        <span v-if="record.report.review_status_code == 'approved'">Contact Completed</span>
                                    </div>
                                    <div v-if="!record.report.is_completed" style="margin-bottom:10px;">
                                        Pending
                                    </div>
                                </div>
                                <a v-if="record.report.num_review_status_logs > 0" :data-id="record.order.id" :data-report_id="record.report.id" @click="showModalContactHistory"><a-icon type="history" /> History</a>
                            </div>
                            <div slot="reportingToClient" slot-scope="text, record">
                                
                                <div v-for="(data, key) in record.state_reportings" :key="key">
                                    <div v-if="data == null">
                                        
                                    </div>
                                    <div v-else style="margin-bottom:15px;">
                                        <div v-if="data.error == ''">
                                            <a @click="showModalStateReporting">
                                                <a-icon type="file-text" />
                                                <span v-for="assay in assayData" :key="assay.key">
                                                    <span v-if="assay.key == key" :data-log_id="data.id">
                                                        State Report
                                                        <br />
                                                        for {{ assay.value }}
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <span v-for="assay in assayData" :key="assay.key">
                                                <span v-if="assay.key == key" :data-log_id="data.id">
                                                    {{ assay.value }}: {{ data.error }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-table>

                    </div>

                </div>

                <div v-if="section == 'security'">

                    <SettingSecurity />

                </div>

                <div v-if="section == 'stats'">

                    <Statistics />

                </div>

                <div v-if="section == 'state-reporting-logs'">

                    <StateReportingLogs />

                </div>

                <div v-if="section == 'patient'">

                    <Patient />

                </div>

                <div v-if="section == 'test'">

                    <Test />

                </div>

                <div v-if="section == 'comment'">

                    <Comment />

                </div>

                <a-modal
                    :title="addCommentTitle"
                    class="modal-add-comment"
                    v-model="addCommentVisible"
                    :visible="addCommentVisible"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="addCommentHandleOk"
                    @cancel="addCommentHandleOk"
                >
                    <div style="font-weight:600;margin:0 0 5px;font-size:90%;">
                        At {{ currentDateTime }}:
                    </div>
                    <div style="margin:0 0 5px;">
                        
                        <a-form
                            id="form-comment"
                            :form="formComment"
                            class="comment-form"
                            layout="vertical"
                            @submit.prevent="handleCommentSubmit"
                        >
                            
                            <a-form-item
                                style="margin:0;"
                                :help="errors.comment"
                            >
                                <a-textarea
                                    v-decorator="[
                                    'comment',
                                        {
                                            rules: [{ required: true, message: 'Please enter your comment.' }],
                                            initialValue: this.targetComment.comment
                                        },
                                    ]"
                                    placeholder="Add your comment here."
                                    :auto-size="{ minRows: 5, maxRows: 10 }"
                                />
                            </a-form-item>

                            <a-form-item
                                style="margin:0 0 15px;font-weight:600;"
                            >
                                <span style="flex:0;margin-right:5px;">
                                    <a-checkbox
                                        v-model="is_flagged"
                                    />
                                </span>
                                <span style="flex:1;">
                                    Flag this comment
                                </span>
                            
                            </a-form-item>

                            <a-button :disabled="formCommentSubmitting" :loading="formCommentSubmitting" type="primary" html-type="submit" class="button-submit">
                                {{ formCommentSubmitting ? 'Submitting...' : (this.targetComment.id ? 'Update' : 'Add') + ' Comment' }}
                            </a-button>

                        </a-form>

                    </div>
                </a-modal>

                <a-modal
                    :title="listCommentTitle"
                    class="modal-list-comment"
                    v-model="listCommentVisible"
                    :visible="listCommentVisible"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="listCommentHandleOk"
                    @cancel="listCommentHandleOk"
                >
                    <div style="overflow-y:scroll;max-height:450px;">
                        <a-list item-layout="horizontal" size="large" :data-source="commentData">
                            <a-list-item slot="renderItem" key="item.id" slot-scope="item">
                                <a class="a-edit" v-if="item.is_editable == 1" slot="actions" @click="showModalEditComment" :data-comment_id="item.id">edit</a>
                                <div>
                                    <span class="comment-meta">
                                        {{ item.created_at | momentDatetime }} by {{ item.from_name }}
                                        <a-icon type="flag" v-if="item.is_flagged" theme="filled" :style="{ color: '#cc0000', fontSize: '16px', margin: '0 0 0 7px' }" />
                                    </span> 
                                    {{ item.comment }}
                                </div>
                            </a-list-item>
                        </a-list>
                    </div>
                </a-modal>

                <a-modal
                    title="Patient Information"
                    v-model="patientVisible"
                    :visible="patientVisible"
                    :confirm-loading="confirmLoading"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="patientHandleOk"
                    @cancel="patientHandleOk"
                    >
                    <a-descriptions size="small" layout="vertical" :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }">
                        <a-descriptions-item label="Patient Name"><span v-html="patientName"></span></a-descriptions-item>
                        <a-descriptions-item label="Gender"><span v-html="patientGender"></span></a-descriptions-item>
                        <a-descriptions-item label="Date of Birth"><span v-html="patientDob"></span></a-descriptions-item>
                        <a-descriptions-item label="Address Line 1"><span v-html="patientAddress1"></span></a-descriptions-item>
                        <a-descriptions-item label="Address Line 2"><span v-html="patientAddress2"></span></a-descriptions-item>
                        <a-descriptions-item label="City"><span v-html="patientCity"></span></a-descriptions-item>
                        <a-descriptions-item label="State"><span v-html="patientState"></span></a-descriptions-item>
                        <a-descriptions-item label="Country"><span v-html="patientCountry"></span></a-descriptions-item>
                        <a-descriptions-item label="Zip Code"><span v-html="patientZipCode"></span></a-descriptions-item>
                        <a-descriptions-item label="Phone Number"><span v-html="patientPhone"></span></a-descriptions-item>
                        <a-descriptions-item label="Email"><span v-html="patientEmail"></span></a-descriptions-item>
                    </a-descriptions>
                </a-modal>

                <a-modal
                    title="Health Questionnaire"
                    class="modal-hq"
                    v-model="hqVisible"
                    :visible="hqVisible"
                    :confirm-loading="confirmLoading"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="hqHandleOk"
                    @cancel="hqHandleOk"
                    >
                    <div v-for="(item, index) in hqQuestion" :key="index">
                        <div>
                            <span style="font-weight:bold;">{{ item.question.question }}: </span>
                            <span>{{ item.answer.label }}</span>
                        </div>
                        <a-divider type="horizontal" style="margin:12px 0;" />
                    </div>
                </a-modal>

                <a-modal
                    title="Contact History"
                    v-model="historyVisible"
                    :visible="historyVisible"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="historyHandleOk"
                    @cancel="historyHandleOk"
                    >

                    <a-timeline style="overflow-y:scroll; max-height: 400px; margin:-10px;padding:10px;" v-if="historyLog.length > 0">
                        <a-timeline-item  v-for="(item, index) in historyLog" :key="index">
                            <div style="font-weight:bold;margin-bottom:2px;">
                                {{ momentDatetimeFormat(item.recorded_at) }}:
                                <span v-if="item.is_contact_successful == '1'">Sucessful Contact</span>
                                by {{item.recorded_by}}
                            </div>
                            {{ item.comment }}
                            <div style="padding-top:5px;" v-if="item.from_status != item.to_status">Status Change: {{ item.to_status }}</div>
                        </a-timeline-item>
                    </a-timeline>

                    <div v-if="historyLog.length == 0">
                        No history found.
                    </div>
                    
                </a-modal>

                <a-modal
                    title="State Report Record"
                    v-model="stateReportVisible"
                    :visible="stateReportVisible"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    @ok="stateReportHandleOk"
                    @cancel="stateReportHandleOk"
                >
                    <div v-if="stateReportLog.error == ''">
                        <div>
                            <span style="font-weight:bold;">Date Submitted:</span>
                            <span>{{ stateReportLog.reported_at }}</span>
                        </div>
                        <a-divider type="horizontal" style="margin:12px 0;" />
                        <div>
                            <span style="font-weight:bold;">Reported By: </span>
                            <span>{{ stateReportLog.reported_by }}</span>
                        </div>
                        <a-divider type="horizontal" style="margin:12px 0;" />
                        <div>
                            <span style="font-weight:bold;">Delivery Method: </span>
                            <span>
                                {{ stateReportLog.method }}
                                <span v-if="stateReportLog.method == 'Email'">
                                    (sent to: {{ stateReportLog.to }})
                                </span>
                            </span>
                        </div>
                        <a-divider type="horizontal" style="margin:12px 0 25px;" />

                        <a-button type="primary" size="default" @click="getStateReportLink" :data-id="stateReportLog.id">
                            Download State Report
                        </a-button>
                    </div>
                    <div v-else>
                        <div style="margin-bottom:30px;">
                            {{ stateReportLog.error }}
                        </div>
                        <a-button type="primary" size="default" @click="stateReportHandleOk">
                            Close
                        </a-button>
                    </div>
                    
                </a-modal>

                <a-modal
                    title="Add Record"
                    v-model="statusVisible"
                    :visible="statusVisible"
                    :cancel-button-props="{ props: { disabled: true } }"
                    :footer=null
                    >

                    <p style="margin:0 0 15px;">You are about to add a contact record for <span style="font-weight:bold" v-html="statusPatientName"></span>.</p>

                    <a-form-model
                        layout="horizontal"
                        ref="formStatus"
                        :model="formStatus"
                        :rules="formStatusRules"
                        :label-col="{ span: 8 }"
                        :wrapper-col="{ span: 24 }"
                        @submit.prevent="handleStatusSubmit"    
                        >

                        <a-input v-model="formStatus.orderId" type="hidden" />
                        <a-input v-model="formStatus.reportId" type="hidden" />

                        <a-form-model-item>
                            <span class="form-label">1. Was the contact successful?</span>
                            <a-radio-group v-model="formStatus.is_contact_successful">
                                <a-radio value="1">
                                Yes
                                </a-radio>
                                <a-radio value="0">
                                No
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item prop="comment">
                            <p class="form-label">Internal Comment:</p>
                            <a-input
                                v-model="formStatus.comment"
                                type="textarea"
                            />
                        </a-form-model-item>
                        <hr />
                        <a-form-model-item>
                            <span class="form-label">2. Release results?</span>
                            <!-- <a-radio-group
                                v-model="formStatus.to_status"
                                :disabled="formStatus.is_contact_successful == 0"
                            > -->
                            <a-radio-group
                                v-model="formStatus.to_status"
                            >
                                <a-radio value="approved">
                                Yes
                                </a-radio>
                                <a-radio value="required">
                                No
                                </a-radio>  
                            </a-radio-group>
                        </a-form-model-item>
                        <div v-if="formStatus.to_status == 'approved'" style="margin-bottom:15px;">
                            <a-alert message="Warning! Selecting yes will instantly release the results report to the client and also send out the required reports to the State." type="warning" show-icon style="font-size:90%;" />
                        </div>
                        <a-form-model-item prop="comment" v-if="formStatus.to_status == 'approved'">
                            <p class="form-label">Comment to be included in the State report:</p>
                            <a-input
                                v-model="formStatus.state_reporting_comment"
                                type="textarea"
                            />
                            <div style="color:#cc0000;line-height:150%;margin-bottom:10px;"><strong>Important!</strong> Any comment that you leave above will show up in the report that is sent to the State.</div>
                        </a-form-model-item>
                        <a-form-model-item v-if="formStatus.is_contact_successful == 0 && formStatus.to_status == 'approved'" style="margin-bottom:25px;">
                            <span class="form-label">Display clinic message to client?</span>

                            <a-checkbox
                                v-model="formStatus.show_letter"
                                :checkAll="formStatus.show_letter"
                            />
                            <span style="margin-left:8px;">
                                Yes, display clinic message
                                <a-popover title="" trigger="hover" placement="bottomRight">
                                    <template #content>
                                        <div style="max-width:350px;">
                                            <p style="font-weight:bold;">
                                                The client will see the clinic message below upon successful login:
                                            </p>
                                            <div style="font-family: 'Courier New', Courier, monospace;">
                                                <p style="margin-bottom:25px;">
                                                    Thank you for purchasing a {{ formStatus.divisionName }} at-home test kit.
                                                    We have partnered with 98point6, an on-demand digital primary care provider to help you review, better understand and if necessary, take action regarding your results. 
                                                </p>
                                                <p style="margin-bottom:0;">
                                                    <span v-if="formStatus.isContactAttempted">
                                                        98point6 has tried contacting you via phone to discuss your lab results and has not been able to connect.
                                                    </span>
                                                    <strong>Your results have been released to you via the {{ formStatus.divisionName }} patient portal and it is important to your health that you access, view and follow up on these results.</strong>
                                                    We recommend that you seek care with a physician for further discussion and guidance in person.
                                                    You can reach a 98point6 physician to review your lab results by contacting the 98point6 Support Team at (866) 657-7991, Option 2.
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                    <font-awesome-icon icon="question-circle" size="lg" :style="{ color: '#666', cursor: 'pointer', marginLeft: '5px' }" />
                                </a-popover>
                            </span>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button :disabled="formStatusSubmitting" :loading="formStatusSubmitting" type="primary" html-type="submit" class="status-form-button">
                                {{ formStatusSubmitting ? 'Submitting...' : 'Submit' }}
                            </a-button>
                            <a-button type="cancel" style="margin-left:8px;" @click="statusHandleOk">
                                Cancel
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>

                </a-modal>

            </a-layout-content>
        </a-layout>
    </div>
</template>

<script>
  // import {mapGetters} from 'vuex'
  import axios from '../api'
  // import VertNav from './VertNav.vue'
  import columns from "../columns.js"
  import moment from 'moment';
  import Comment from './account/Comment.vue'
  import Patient from './account/Patient.vue'
  import SettingSecurity from './account/SettingSecurity.vue'
  import Statistics from './account/Statistics.vue'
  import StateReportingLogs from './account/StateReportingLogs.vue'
  import Test from './account/Test.vue'

  export default {
    name: 'Account',
    components: {
        Comment,
        Patient,
        SettingSecurity,
        Statistics,
        StateReportingLogs,
        Test
    },
    computed: {
        // ...mapGetters(['getUser']),
        isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
        user : function(){ return this.$store.getters.getUser},
        token : function(){ return this.$store.getters.getToken}
    },
    mounted: function() {
        if (this.section != 'security' && this.section != 'stats' && this.section != 'state-reporting-logs' && this.section != 'patient') {
            this.getPhysicianListRequest();
            this.getOrderListRequest();
            this.getAssayListRequest();
            this.getProductListRequest();
        }
        this.getRenderedColumns();
        this.getReviewStatusRequest();
        this.getCountListRequest();
        this.getCommentCountRequest();
        this.$nextTick(function () {
            console.log('section: ' + this.section);
            // console.log('reviewStatus: ' + this.reviewStatus);
        })
    },
    beforeCreate() {
        this.formStatus = this.$form.createForm(this, { name: 'form_status' });
        this.formComment = this.$form.createForm(this, { name: 'normal_comment' });
        // this.isMobileMenu = window.innerWidth <= 767 ? '600' : '200';
        // console.log(this.isMobileMenu);
    },
    updated() {
        
    },
    data: function() {

        let validateComment = (rule, value, callback) => {
            // console.log('validateComment loading.');
            // console.log(rule);
            // console.log(value);
            // console.log(this.formStatus.to_status);
            // if (value === '' && this.formStatus.to_status == 'approved') {
                // console.log('validateComment failed.');
                // callback(new Error('Comment is required if "Okay to release result" is selected.'));
            // }
            // else {
                // console.log('validateComment passed.');
                callback();
            // }
        };

        return {
            error: '',
            errors: {},
            data: [],
            pagination: {},
            loading: false,
            columns,
            ModalText: 'Content of the modal',
            isCollapsed: this.collapsed,
            appSidebar: false,
            patientVisible: false,
            hqVisible: false,
            statusVisible: false,
            historyVisible: false,
            popoverVisible: false,
            addCommentVisible: false,
            listCommentVisible: false,
            stateReportVisible: false,
            formCommentSubmitting: false,
            formStatusSubmitting: false,
            confirmLoading: false,
            currentDateTime: moment().format('lll'),
            targetComment: {
                id: '',
                comment: '',
                is_flagged: false
            },
            is_flagged: false,
            commentType: '',
            commentTypeId: '',
            validateStatus: '',
            commentData: [],
            addCommentTitle: '',
            listCommentTitle: '',
            patientName: '',
            patientDob: '',
            patientGender: '',
            patientAddress1: '',
            patientAddress2: '',
            patientCity: '',
            patientState: '',
            patientCountry: '',
            patientZipCode: '',
            statusPatientName: '',
            patientEmail: '',
            patientPhone: '',
            pageTitle: '',
            section: this.$route.meta.section ? this.$route.meta.section : (this.$route.params.section ? this.$route.params.section : 'result'),
            sectionParent: this.$route.params.section && this.$route.params.section == 'general' || this.$route.params.section == 'security' ? 'setting' : 'dashboard',
            formPatientSearch: {
                q: '',
                d: '',
                type: 'name'
            },
            formStatus: {
                orderId: '',
                reportId: '',
                recorded_at: moment(new Date(), 'YYYY-MM-DD hh:mm:ss'),
                is_contact_successful: '0',
                to_status: 'required',  
                show_letter: true,
                comment: '',
                state_reporting_comment: ''
            },
            hqQuestion: [],
            historyLog: [], 
            stateReportLog: [],
            physicianData: [],
            reviewStatusData: [],
            countData: [],
            commentCountData: [],
            assayData: [],
            productData: [],
            rephId: '',
            assayId: '',
            productId: '',
            isReportingRequired: '',
            reviewStatus: this.$route.params.section ? this.$route.params.section : 'result',
            renderedColumns: [],
            formStatusRules: {
                comment: [{
                    validator: validateComment,
                    // required: true,
                    // message: 'Please enter comment.',
                    trigger: 'change'
                }]  
            }
        };
    },

    methods: {

        onSiderCollapse(collapsed, type) {
            var collapsedWidth = 767;
            if (type != 'clickTrigger') {
                // this.appSidebar = window.innerWidth <= collapsedWidth ? true : false;
            }
            if (window.innerWidth <= collapsedWidth) {
                // console.log('window innerWidth is ' + window.innerWidth);
                // console.log(document.getElementsByClassName('ant-layout-sider')[0]);s   
            }
        },

        onSiderBreakpoint() {
            // console.log('dingg.......');
            // console.log(broken);
        },

        momentDatetimeFormat(args) {
            return moment(args).format('MMM DD, YYYY HH:mm:ss');
        },

        statusCaption(args) {
            return args == 1 ? 'Okay to release result' : 'Cannot release result';
        },

        onChangeSearchType(value) {
            // console.log('...value: ' + value);
            if (value == 'birth_date') {
                this.formPatientSearch.q = '';
                // document.getElementsByClassName('ant-calendar-picker')[0].style.display = 'inline-block';
                // document.getElementsByClassName('ant-input')[0].style.display = 'none';
            }
            else {
                this.formPatientSearch.d = '';
                // document.getElementsByClassName('ant-calendar-picker')[0].style.display = 'none';
                // document.getElementsByClassName('ant-input')[0].style.display = 'inline-block';
            }
        },

        onChangePatientDob() {
            this.formPatientSearch.type = 'birth_date';
        },

        handleLogoutClick() {
            this.$store.dispatch('setUser', {});
            this.$store.dispatch('setToken', null);
            this.$store.dispatch('setRefreshToken', null);
            this.$router.push('/home');
        },

        showModalPatient: async function(event) {
            this.patientVisible = true;
            var orderId = event.target.getAttribute('data-id');
            // console.log(orderId);

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/patient', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            this.patientName = res.data.patient.first_name + ' ' + res.data.patient.last_name;  
            this.patientDob = res.data.patient.birth_date;
            this.patientGender = res.data.patient.gender;
            this.patientAddress1 = res.data.patient.address_1;
            this.patientAddress2 = res.data.patient.address_2;
            this.patientCity = res.data.patient.city;
            this.patientState = res.data.patient.state;
            this.patientCountry = res.data.patient.country;
            this.patientZipCode = res.data.patient.zip_code;
            this.patientEmail = res.data.patient.email;
            this.patientPhone = '<a href="tel:' + res.data.patient.phone + '">' + res.data.patient.phone + '</a>';

            // console.log('......showModalPatient........');
            // console.log(this.patientName);
            // console.log(res);

        },

        async getRenderedColumns() {
            this.renderedColumns =  this.$route.params.section == 'approved' || this.$route.params.section == 'state_reporting_missing' ? columns : columns.filter(item => !item.hidden);
        },

        async getNumComments() {

            var uriCommentCount = 'comments/count?' + this.commentType + '=' + this.commentTypeId;

            let res = await axios.get(uriCommentCount , {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            this.numComments = res.data.count;

            console.log('this.comments');
            console.log(this.numComments);

            if (parseInt(this.numComments.flagged) > 0) {
                document.getElementsByClassName('num-comments')[0].classList.add('alert');
            }

            document.getElementsByClassName('num-comments')[0].innerHTML = this.numComments.all + ' comment' + (parseInt(this.numComments.all) > 1 ? 's' : '') + (parseInt(this.numComments.flagged) > 0 ? ' (' + parseInt(this.numComments.flagged) + ' flagged)' : '');

        },

        showModalAddComment: async function() {
            this.targetComment.comment = '';
            this.targetComment.id = '';
            this.targetComment.is_flagged = false;
            this.formComment.resetFields('comment'); 
            this.getNumComments();

            var modalTitle = 'Order ID ' + this.testData.client_order.full_id;
            this.addCommentTitle = 'Add Comment: ' + modalTitle;

            this.listCommentVisible = false;
            this.addCommentVisible = true;
        },

        showModalEditComment: async function(event) {
            var commentId = event.target.getAttribute('data-comment_id');
            // console.log('targeted comment_id: ' + commentId);

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'comments/' + commentId, {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('edit-comment');
            // console.log(res.data);

            this.addCommentTitle = 'Edit Comment ID: ' + commentId;

            this.targetComment = res.data.comment;
            this.is_flagged = this.targetComment.is_flagged;

            this.listCommentVisible = false;
            this.addCommentVisible = true;
        },

        showModalListComment: async function(event) {
            
            this.listCommentVisible = true;

            var modalTitle = event.target.getAttribute('data-comment_type') == 'acct_id' ? 'Patient ID ' + event.target.getAttribute('data-comment_type_id') : event.target.getAttribute('data-comment_type_label') + ' (ID: ' + event.target.getAttribute('data-comment_type_full_id') + ')';

            this.listCommentTitle = 'List Comment: ' + modalTitle;

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'comments?' + event.target.getAttribute('data-comment_type') + '=' + event.target.getAttribute('data-comment_type_id'), {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            this.commentType = event.target.getAttribute('data-comment_type');
            this.commentTypeId = event.target.getAttribute('data-comment_type_id');

            this.commentData = res.data.comments;
            // console.log(this.commentData);

        },

        async handleCommentSubmit() {

            // console.log('submitting comment form.');

            try {

                this.formCommentSubmitting = true;

                const firstCommentRes = await this.formComment.validateFields();

                if (firstCommentRes) {

                    // console.log(firstCommentRes.comment);
                    // console.log(this.targetComment.id);
                    // console.log(this.is_flagged);

                    var uriPostComment = '';

                    if (this.targetComment.id) {
                        uriPostComment = 'comments/' + this.targetComment.id
                    }
                    else {
                        uriPostComment = 'comments?' + this.commentType + '=' + this.commentTypeId;
                    }

                    // console.log('uriPostComment');
                    // console.log(uriPostComment);
                    
                    const commentRes = await axios.post(uriPostComment, {
                        comment: firstCommentRes.comment,
                        is_flagged: this.is_flagged
                    }, {headers: {
                        'Authorization': 'Bearer ' + this.token
                    } } );

                    if (commentRes) {
                        
                        this.error = '';
                        this.errors = {};
                        this.validateStatus = '';
                        
                        this.formCommentSubmitting = false;
                        this.addCommentVisible = false;

                        this.$message.success('Your comment has been ' + (this.targetComment.id ? 'updated' : 'added') +' successfully.', 5);

                        this.getNumComments();
                        
                    }

                }

            } catch(e) {
                this.formCommentSubmitting = false;
                var err = e.response.data.error;
                this.error = err;
                this.errors = e.response.data.errors;
                this.validateStatus = 'error';
            }
            
        },

        getPatientLink: async function(event) {
            var acctId = event.target.getAttribute('data-acct_id');
            window.location.href = process.env.BASE_URL + 'account/patient/' + acctId;
        },

        getTestLink: async function(event) {
            var reportId = event.target.getAttribute('data-report_id');
            window.location.href = process.env.BASE_URL + 'account/test/' + reportId;
        },

        showModalHq: async function(event) {
            this.hqVisible = true;
            var orderId = event.target.getAttribute('data-id');

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/health-questionnaire', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('......showModalHq........');
            // console.log(res.data.health_questionnaire);
            // console.log(res.data.health_questionnaire.question);
            // console.log(res.data.health_questionnaire.answer);

            this.hqQuestion = res.data.health_questionnaire;

        },

        showModalContactHistory: async function(event) {
            
            this.historyVisible = true;

            var reportId = event.target.getAttribute('data-report_id');
            
            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId + '/review-status-logs', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('......showModalHistory........');
            // console.log(orderId);
            // console.log(res.data.logs);

            this.historyLog = res.data.logs;

        },

        showModalSetStatus: async function(event) {
            this.statusVisible = true;
            var orderId = event.target.getAttribute('data-id'),
                reportId = event.target.getAttribute('data-report_id'),
                divisonName = event.target.getAttribute('data-division_name'),
                isContactAttempted = event.target.getAttribute('data-is_contact_attempted');

            /*
            let resReport = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId, {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });
            */
            
            this.formStatus.orderId = orderId;
            this.formStatus.reportId = reportId;
            this.formStatus.recorded_at = moment().format('YYYY-MM-DD HH:mm:ss');
            this.formStatus.comment = '';
            this.formStatus.divisionName = divisonName;
            this.formStatus.isContactAttempted = isContactAttempted;
            this.formStatus.is_contact_successful = '0';
            // this.formStatus.show_letter = resReport.data.report.report.show_letter;

            // console.log('show formStatus');

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/patient', {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            // console.log('......showModalSetStatus........');
            // console.log(moment().format('YYYY-MM-DD HH:mm:ss'));

            this.statusPatientName = res.data.patient.first_name + ' ' + res.data.patient.last_name;

        },

        showModalStateReporting: async function (event) {

            this.stateReportVisible = true;

            var logId = event.target.getAttribute('data-log_id');

            console.log(event.target);

            let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'state-reportings/' + logId, {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            console.log('......showModalStateReporting........');
            console.log(logId);
            console.log(res);

            this.stateReportLog = res.data.state_reporting;

        },

        getRequisitionLink: async function(event) {
            var orderId = event.target.getAttribute('data-id');
            window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + orderId + '/requisition?access_token=' + this.token, '_blank');
        },

        getReportLink: async function(event) {
            var reportId = event.target.getAttribute('data-report_id');
            window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + reportId + '/pdf?access_token=' + this.token, '_blank');
        },

        getStateReportLink: async function (event) {
            var logId = event.target.getAttribute('data-id');
            window.open(process.env.VUE_APP_API_REPH_URL_GFRONT + 'state-reportings/' + logId + '/download?access_token=' + this.token, '_blank');
        },

        patientHandleOk() {
            this.patientVisible = false;
        },

        hqHandleOk() {
            this.hqVisible = false;
        },

        statusHandleOk() {
            this.statusVisible = false;
        },
        
        historyHandleOk() {
            this.historyVisible = false;
        },

        stateReportHandleOk() {
            this.stateReportVisible = false;
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination = {
                ...this.pagination,
                current: pagination.current
            };

            this.getOrderListRequest({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        },
        
        async handlePatientSubmit() {

            // console.log('...... handlePatientSubmit ......');

            // console.log(this.formPatientSearch.q);
            // console.log(this.formPatientSearch.d);
            // console.log(this.formPatientSearch.type);

            this.loading = true;

            var keyword = '',
                uri = '';
            if (this.formPatientSearch.q == '' && (this.formPatientSearch.d == '' || this.formPatientSearch.d == null)) {
                // Do nothing.
            }
            else {
                keyword = '&' + this.formPatientSearch.type + '=' + (this.formPatientSearch.type == 'birth_date' ? moment(this.formPatientSearch.d).format('YYYY-MM-DD') : this.formPatientSearch.q);
            }

            if (this.section == 'range') {
                uri = process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + this.$route.query.type + '?from=' + this.$route.query.from + '&to=' + this.$route.query.to + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : '') + keyword;

            }
            else {
                uri = process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : '') + keyword;
            }

            let res = await axios.get(uri, {
                headers: { 'Authorization': 'Bearer ' + this.token }
            });

            this.getPageTitle();
            
            this.loading = false;
            this.data = res.data.client_orders;
            this.pagination = {
                ...this.pagination,
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count
            };

        },

        async handleStatusSubmit() {
            
            // console.log('blah');
            // console.log(this.formStatus);
            // console.log('validate check...');

            this.formStatusSubmitting = true;

            this.$refs.formStatus.validate(valid => {
                if (valid) {
                    if (this.formStatus.to_status == 'required') {
                        this.formStatus.show_letter = false;
                    }

                    axios.post(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/' + this.formStatus.reportId  + '/review-status', this.formStatus, {
                        headers: { 'Authorization': 'Bearer ' + this.token }
                    }).then(() => {       
                        // res inside bracket.   
                        // console.log('....submitted.');
                        // console.log(res);
                        this.statusVisible = false;
                        location.reload();
                    })
                    .catch(() => {
                        this.formStatusSubmitting = false;
                        // error inside bracket.
                        // console.log('....error.');
                        // console.log(error.response);
                    })

                    // console.log('submit!');
                }
                else {
                    // console.log('error submit!!');
                    this.formStatusSubmitting = false;
                    return false;
                }
            });

        },

        async handlePhysicianChange(value) {
            
            this.loading = true;

            this.rephId = value;

            let res = await axios.get('orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : ''), {
                headers: { 'Authorization': 'Bearer ' + this.token },
                timeout: 30000
            });

            // console.log('.....handlePhysicianChange......');
            // console.log(rephId);
            // console.log(res);    
            
            this.loading = false;
            this.data = res.data.client_orders;
            this.pagination = {
                ...this.pagination,
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count
            };

        },

        async handleAssayChange(value) {
            
            this.loading = true;

            this.assayId = value;

            let res = await axios.get('orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : ''), {
                headers: { 'Authorization': 'Bearer ' + this.token },
                timeout: 30000
            });

            // console.log('.....handlePhysicianChange......');
            // console.log(rephId);
            // console.log(res);    
            
            this.loading = false;
            this.data = res.data.client_orders;
            this.pagination = {
                ...this.pagination,
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count
            };

        },

        async handleProductChange(value) {
            
            this.loading = true;

            this.productId = value;

            let res = await axios.get('orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : ''), {
                headers: { 'Authorization': 'Bearer ' + this.token },
                timeout: 30000
            });

            // console.log('.....handlePhysicianChange......');
            // console.log(rephId);
            // console.log(res);    
            
            this.loading = false;
            this.data = res.data.client_orders;
            this.pagination = {
                ...this.pagination,
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count
            };

        },

        async handleReportableChange(value) {

            this.loading = true;

            this.isReportingRequired = value;

            let res = await axios.get('orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : ''), {
                headers: { 'Authorization': 'Bearer ' + this.token },
                timeout: 30000
            });

            // console.log('.....handlePhysicianChange......');
            // console.log(rephId);
            // console.log(res);    
            
            this.loading = false;
            this.data = res.data.client_orders;
            this.pagination = {
                ...this.pagination,
                pageSize: res.data.pagination.item_count_per_page,
                total: res.data.pagination.total_item_count
            };

        },

        async handleMenuClick({key}) {

            console.log('key => ' + key);

            this.loading = true;

            this.section = key;

            this.$router.replace(process.env.BASE_URL + 'account/' + key);

            var collapsedWidth = 767;
            if (window.innerWidth <= collapsedWidth) {
                // console.log('menu needs to be collapsed.');
                this.appSidebar = true;
            }

            if (key == 'security') {

                // console.log('yah');

            }

            else if (key == 'stats' || key == 'state-reporting-logs') {

                this.getPageTitle();

            }

            else {

                try {

                    this.getRenderedColumns();

                    if (key == 'state_reporting_missing') {
                        this.reviewStatus = 'approved&state_reporting=missing';
                    }
                    else if (key == 'range') {
                        this.reviewStatus = '';
                    }
                    else {
                        this.reviewStatus = key;
                    }

                    let res = await axios.get('orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : ''), {
                        headers: { 'Authorization': 'Bearer ' + this.token },
                        timeout: 30000
                    });

                    this.loading = false;
                    this.data = res.data.client_orders;
                    this.pagination = {
                        ...this.pagination,
                        pageSize: res.data.pagination.item_count_per_page,
                        total: res.data.pagination.total_item_count
                    };

                } catch(error) {
                    console.log('.....error.....');
                    console.log(error);
                }

                this.getPageTitle();

            }

        },

        async getPhysicianListRequest() {
            
            try {
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'physicians/select-options', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                this.physicianData = res.data.options;
            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getReviewStatusRequest() {
            
            try {
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'reports/review-statuses/select-options', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                // console.log('.....getReviewStatusRequests.......');
                // console.log(res.data.options);

                this.reviewStatusData = res.data.options;
            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getAssayListRequest() {
            
            try {
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'assays/select-options', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                // console.log('.....getAssayListRequest.......');
                // console.log(res.data.options);

                this.assayData = res.data.options;

            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getProductListRequest() {
            
            try {
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'products/select-options', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                // console.log('.....getProductListRequest.......');
                // console.log(res.data.options);

                this.productData = res.data.options;
            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getOrderListRequest() {
            
            try {
                this.loading = true;

                var uriOrders = '';

                this.getRenderedColumns();

                if (this.reviewStatus == 'state_reporting_missing') {
                    this.reviewStatus = 'approved&state_reporting=missing';
                }

                if (this.section == 'range') {
                    this.reviewStatus = 'all';
                    uriOrders = process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/' + this.$route.query.type + '?from=' + this.$route.query.from + '&to=' + this.$route.query.to + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : '') + (this.pagination.current === undefined ? '' : '&page=' + this.pagination.current);
                }

                else {
                    uriOrders = process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders?' + (this.rephId ? 'reph_id=' + this.rephId : '') + (this.reviewStatus != 'all' ? '&review_status=' + this.reviewStatus : '') + (this.isReportingRequired != '' ? '&is_reporting_required=' + this.isReportingRequired : '') + (this.assayId != '' ? '&alinity_assay_id=' + this.assayId : '') + (this.productId != '' ? '&product_id=' + this.productId : '') + (this.pagination.current === undefined ? '' : '&page=' + this.pagination.current);
                }

                console.log(uriOrders);

                let res = await axios.get(uriOrders, {
                        headers: { 'Authorization': 'Bearer ' + this.token },
                        timeout: 30000
                    });

                // console.log('pagination');
                // console.log(this.pagination);
                // console.log(this.pagination.current);
                // console.log(this.pagination.current === undefined);
                
                this.getPageTitle();
                
                this.loading = false;
                this.data = res.data.client_orders;

                // console.log('.....getOrderListRequest......');
                // console.log(this.data);

                this.pagination = {
                    ...this.pagination,
                    size: 'small',
                    pageSize: res.data.pagination.item_count_per_page,
                    total: res.data.pagination.total_item_count,
                    showTotal: (num, range) => range[0] + ' to ' + range[1] + ' of ' + num + ' Tests'
                };

            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        addCommentHandleOk() {
            this.addCommentVisible = false;
        },

        listCommentHandleOk() {
            this.listCommentVisible = false;
        },

        async getCountListRequest() {

            try {
            
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'orders/count', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                // console.log('......getCountListRequest........');
                // console.log(res.data.client_orders_count);

                this.countData = res.data.client_orders_count;

            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getCommentCountRequest() {

            try {
            
                let res = await axios.get(process.env.VUE_APP_API_REPH_URL_GFRONT + 'comments/all/count', {
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    timeout: 30000
                });

                // console.log('......getCountListRequest........');
                // console.log(res.data.client_orders_count);

                this.commentCountData = res.data.count;

            } catch(error) {
                console.log('.....error.....');
                console.log(error);
            }

        },

        async getPageTitle() {

            console.log('.......getPageTitle......');
            console.log(this.reviewStatus);
            console.log(this.reviewStatusData);

            var i,
                title = 'Patient Tests';

            if (this.section == 'stats') {
                title = 'Statistics';
            }
            else if (this.section == 'state-reporting-logs') {
                title = 'State Reporting Logs';
            }
            else if (this.section == 'patient') {
                title = '';
            }
            else if (this.section == 'test') {
                title = '';
            }
            else if (this.section == 'comment') {
                title = 'Comments';
            }
            else if (this.section == 'range') {
                switch (this.$route.query.type) {
                    case 'critical':
                        title += ' (Critical)';
                        break;
                    case 'contacted':
                        title += ' (Contacted)';
                        break;
                    case 'activated':
                        title += '';
                        break;
                    default:
                        title += '';
                }
                title += ' <span style="font-size:70%;margin-left:15px;">from ' + moment(this.$route.query.from).format('MMM D, YYYY') + ' to ' + moment(this.$route.query.to).format('MMM D, YYYY') + '</span>';

            }
            else {
                if (this.reviewStatus == 'all') {
                    title += ' - All Tests';
                }
                else {
                    for (i = 0; i < this.reviewStatusData.length; i++) {
                        console.log(this.reviewStatusData[i]);
                        if (this.reviewStatusData[i].key == this.reviewStatus) {
                            title += ' - ' + this.reviewStatusData[i].value;
                        }
                    }
                }
            }
            if (title != '') {
                document.getElementsByClassName('ant-page-header-heading-title')[0].innerHTML = title;
            }

        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }
        
    }

  }
</script>

<style>
#user-console h3 { margin-bottom:20px; }
.ant-input { font-size:12px; }
.ant-table { font-size:12px; }
.ant-select { font-size:12px; }
.ant-btn { font-size:12px; }
.ant-form-item-control .ant-btn { padding:0 4px; }
.ant-timeline, .ant-timeline-item { font-size:12px; }
.ant-descriptions-item-label {font-size: 12px; color:#999; }
.ant-descriptions-row:nth-child(odd) .ant-descriptions-item {padding-bottom:0;}
.ant-descriptions-row:nth-child(even) .ant-descriptions-item {padding-bottom:15px;}
.div-select-physician {margin-bottom:15px;}
.div-select-physician .ant-input-group {width:400px;}
.div-select-physician .ant-select {width:250px;}
.div-select-physician .ant-select.shorter {width:175px;}
.div-select-physician .ant-select.longer {width:250px;}
.ant-select-dropdown-menu-item {font-size:12px;}
.ant-form-item {margin-bottom:3px;}
.ant-table-row-cell-break-word {word-break:normal !important;}

.ant-layout-sider {flex: 0 0 200px; max-width: 200px; min-width: 200px; width: 200px; background: rgb(255, 255, 255);}
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-item {
    font-size:12px;
}
.txt {
    margin:0 0 25px;
}
.txt .ant-form-item-control {
    line-height:normal;
}
p.form-label, span.form-label {
    color:#333;
    margin:0 25px 0 0;
    line-height:normal;
    font-size:1.1em;
}
p.form-label {
    margin:10px 0 5px;
}
.vert-nav-header {
  height: 100px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  background-color: #31396d;
}
.vert-nav-header a {
  color:#6bb8ff;
}
.vert-nav-header a:hover {
  color:#fff;
}
.typography-ellipsis {
  color:#fff;
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.div-select-physician .ant-input {
    height:32px ;
}
.ant-layout-sider {
    flex: 0 0 230px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    width: 230px !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height:auto;
    line-height: 150%;
    padding:10px 20px 10px 0;
    margin:0 0 15px;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    text-overflow:initial;
    overflow:initial;   
}
.ant-menu-item, .ant-menu-submenu-title {
    white-space: initial;
}

@media only screen and (max-width: 767.99px) {
    .ant-radio-group {
        margin-top:8px;
    }
    .ant-radio-group .ant-radio-wrapper {
        display:block;
        margin:0 0 8px;
    }
    .div-select-physician .ant-input-group,
    .div-select-physician .ant-select,
    .ant-form-inline .ant-form-item  {
        width:100%;
    }
    .ant-layout-sider-zero-width-trigger-left {
        position: absolute;
        top: -55px;
        right: -55px;
    }
    aside:not(.ant-layout-sider-collapsed) > .ant-layout-sider-zero-width-trigger-left {
        position: absolute !important;
        top: -55px !important;
    }
    .ant-layout-sider {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
    }
    .ant-layout-sider.ant-layout-sider-zero-width {
        flex: 0 0 0 !important;
        max-width: 0 !important;
        min-width: 0 !important;
        width: 0 !important;
    }
}

@media only screen and (max-width: 574.99px) {
    .div-select-physician .ant-select.shorter {width:100%;}
    .div-select-physician .ant-select.longer {width:100%;}
}

</style>

<style scoped>
.comment-meta {display:block;font-size:12px;font-weight:600;color:#000;margin:0 0 3px;}
.ant-list-items > li:first-child {padding-top:0;}
.a-edit {font-size:90%;}
hr {margin:20px 0;border: none; height: 1px; /* Set the hr color */ color: #ccc;  /* old IE */ background-color: #ccc;  /* Modern Browsers */}
</style>