<template>
    <div v-if="error" style="margin-bottom:20px;">
        <div data-show="true" class="ant-alert ant-alert-error ant-alert-banner">
            <i aria-label="icon: close-circle" class="anticon anticon-close-circle ant-alert-icon">
                <svg viewBox="64 64 896 896" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="">
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                </svg>
            </i>
            <span class="ant-alert-message" v-html="this.error"></span>
            <span class="ant-alert-description"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    props: ['error']
}
</script>